import React, { useEffect } from 'react'
import HeaderPage from '../HeaderPage'
import Footer from '../Footer'
import img1 from '../../Assets/library1.jfif'
import img2  from '../../Assets/library2.jfif'
import img3  from '../../Assets/library3.jfif'

const Library = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
<HeaderPage />

<div className="container-fluid">

    <div className="row p-0 text-center" style={{backgroundColor:"brown",color:"white"}}>
     <div className="col-12 py-3">
        {/* <p style={{fontSize:"25px"}}>You are here</p> */}
        <span> <span style={{color:"#f9a693"}}>Home / Academics </span> / Library </span>
     </div>
   </div>

   <div className="row p-0 py-5 px-md-5"  style={{backgroundColor:"white"}}>
          <div className="col-12 mb-5 text-start px-md-5">
            <h2 style={{ marginBottom: '-10px' }}>Library</h2>
            <span
              style={{
                color: '#926505',
                textDecoration: 'underline',
                fontWeight: 'bolder',
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </div>

          <div className="col-12 p-0 px-md-5 px-2">
            <ul>
              <li>
                <a >
                  <figure>
                    <img
                      className="gallaryImg"
                      src={img1}
                      alt="image"
                    />
                  </figure>
                </a>
              </li>
              <li>
                <a >
                  <figure>
                    <img
                      className="gallaryImg"
                      src={img2}
                      alt="image"
                    />
                  </figure>
                </a>
              </li>
              <li>
                <a >
                  <figure>
                    <img
                      className="gallaryImg"
                      src={img3}
                      alt="image"
                    />
                  </figure>
                </a>
              </li>             
            </ul>
          </div>
        </div>

</div>

<Footer />
    </>
  )
}

export default Library