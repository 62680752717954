import React, { useEffect } from 'react'
import HeaderPage from '../HeaderPage'
import Footer from '../Footer'
import clgImg from '../../Assets/College.jpeg'
import img1 from '../../Assets/img1.jfif'
import img2  from '../../Assets/img2.jfif'
import img3  from '../../Assets/img3.jfif'
import img4  from '../../Assets/img4.jfif'
import img5  from '../../Assets/img5.jfif'
import img6  from '../../Assets/img6.jfif'

const Infrastructure = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
<HeaderPage />

<div className="container-fluid">
   
    <div className="row p-0 text-center" style={{backgroundColor:"brown",color:"white"}}>
     <div className="col-12 py-3">
        {/* <p style={{fontSize:"25px"}}>You are here</p> */}
        <span> <span style={{color:"#f9a693"}}>Home / Academics </span> / Infrastructure </span>
     </div>
   </div>

    <div className="row p-0" style={{ backgroundColor: '#F2F4F4' }}>
              <div className="col-12 p-0">
                <img src={clgImg} alt="clgImg" className='clgImg' style={{width:"100%",minHeight:"300px",maxHeight:"600px"}} />
              </div>
    </div>

    <div className="row p-0 py-5 px-md-5" style={{ backgroundColor: '#F2F4F4' }}>

          <div className="col-12 px-md-5">
            <h2 style={{ marginBottom: '-15px' }}>
                Infrastructure
            </h2>
            <span
              style={{
                color: '#926505',
                textDecoration: 'underline',
                fontWeight: 'bolder',
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </div>

          <div className="col-12 px-md-5 pt-5">
            <p style={{fontSize:"18px",marginBottom:"0px",}}>The Site -</p>
            <span>The location of site is an major road Tasgaon - Kavthe Mahankal. The site is approachable by 12 m wide side service road with 9 m wide internal road in campus.</span>
            </div>
          
            <div  className="col-12 px-md-5 pt-3">
            <p style={{fontSize:"18px",marginBottom:"0px"}}>Land Use Pattern -</p>
            <span>The total land under educational use is about 2.8 acre and the land is subdivided in two parts one is for playground admeasuring 4105 sq. m. and educational area is about 6153 sq. m.</span>
            </div>

            <div  className="col-12 px-md-5 pt-3">
            <p style={{fontSize:"18px",marginBottom:"0px"}}>Design Concept -</p>
            <span>It is an L shaped college building with a central courtyard surrounded by wide passage which connects all rooms.</span>
            </div>

            <div  className="col-12 px-md-5 pt-3">
            <p style={{fontSize:"18px",marginBottom:"0px"}}>Buildings and Facilities in the Campus -</p>
            <span>It is specially designed building for educational purpose with all the facilities required to meet the desirable end than the minimum requirement. It has all the required amenities.</span>
            <span>The main building is for academic purpose with all requirements of Pharmacy College. A separate cafeteria and playground in front of the academic building. A multipurpose hall is adjacent to the college building.</span>
            </div>

            <div  className="col-12 px-md-5 pt-3">
            <p style={{fontSize:"18px",marginBottom:"0px"}}>External Services -</p>
            <span>Electric supply has been hired from MSEB, Fire extinguishing equipment are provided at necessary places</span>
            </div>

            <div  className="col-12 px-md-5 pt-3">
            <p style={{fontSize:"18px",marginBottom:"0px"}}>Construction Systems and Materials -</p>
            <span>The building is constructed in RCC framed G + 2 Building with Ground and First floor construction completed in all aspects. Walls are made with bricks masonary and sand faced plaster. Flooring tile are tandoor stale, and electrification by casing and caping.</span>
            </div>

            <div  className="col-12 px-md-5 pt-3">
              <p style={{fontSize:"18px",marginBottom:"0px"}}>Landscape Proposal -</p>
              <span>The courtyard is designed with flowery plants. The boundaries of the campus are surrounded by coconut tree plantation. The playground boundaries are with flowery plants.</span>
            </div>
          
    </div>

        {/* ========================Gallary Images================== */}

        <div className="row p-0 py-5 px-md-5"  style={{backgroundColor:"white"}}>
       

          <div className="col-12 p-0 px-md-5 px-2">
            <ul>
              <li>
                <a>
                  <figure>
                    <img
                      className="gallaryImg"
                      src={img1}
                      alt="image"
                    />
                  </figure>
                </a>
              </li>
              <li>
                <a>
                  <figure>
                    <img
                      className="gallaryImg"
                      src={img2}
                      alt="image"
                    />
                  </figure>
                </a>
              </li>
              <li>
                <a>
                  <figure>
                    <img
                      className="gallaryImg"
                      src={img3}
                      alt="image"
                    />
                  </figure>
                </a>
              </li>
              <li>
                <a>
                  <figure>
                    <img
                      className="gallaryImg"
                      src={img4}
                      alt="image"
                    />
                  </figure>
                </a>
              </li>
              <li>
                <a>
                  <figure>
                    <img
                      className="gallaryImg"
                      src={img5}
                      alt="image"
                    />
                  </figure>
                </a>
              </li>
              <li>
                <a>
                  <figure>
                    <img
                      className="gallaryImg"
                      src={img6}
                      alt="image"
                    />
                  </figure>
                </a>
              </li>
            </ul>
          </div>
        </div>


</div>

<Footer />
    </>
  )
}

export default Infrastructure