import React, { useEffect, useRef, useState } from 'react';
import { AiFillEdit } from "react-icons/ai";
import { AiFillCloseCircle } from "react-icons/ai";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import axios from 'axios';
import { accessImage, dashboardCreate, dashboardFindAll, dashboardUpdate, } from '../AdminAllAPIs';
import { toast } from 'react-toastify';
import ProfilePage from './ProfilePage';
import { Navigate, useNavigate } from 'react-router-dom';
import './DashboardCSS.css' ;

const Dashboard = () => {


  const navigate = useNavigate();

  // ===============Check session storage==============
  useEffect(() => {
    if (!sessionStorage.getItem('userData')) { 
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    const disableBackButton = (event) => {
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = function () {
        window.history.pushState(null, null, window.location.href);
      };
    };

    disableBackButton();

    return () => {
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = null;
    };
  }, []);
  

//==========================================================================

//=======================================================================================================
    const [selectedFileName, setSelectedFileName] = useState("");
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);
;

    const handleCloseModal = () => {
        setSelectedItemId(null);
        setOpenDeleteModal(false);
    };
    
    const [tableData, setTableData] = useState([]);


    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [open1, setOpen1] = React.useState(false);
    const handleOpen1 = () => setOpen1(true);
    const handleClose1 = () => setOpen1(false);

    const [formData, setFormData] = useState({
        file: null
      });

    
      const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFormData({ ...formData, file });
      };
  
    

    //   ==========SUBMIT DATA API CODE================

      const handleSubmit = (event) => {
        event.preventDefault();
        const { file } = formData;

          // Check if file size is greater than 1MB
          if (file && file.size > 1024 * 1024) {
            toast.error('File size exceeds 1MB limit!', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            return; 
          }
        
        const formDataToSend = new FormData();
        formDataToSend.append('file', file);
    
    
        axios.post(dashboardCreate, formDataToSend)
          .then(response => {
            if(response.data == "SAVED"){

                toast.success('Data Saved Successfully!!', {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                  });

                fetchData();
            }
           
            handleClose();
          })
          .catch(error => {
            console.error('Error:', error);
          });
      };


    // =================GET DATA API CODE======================


    const fetchData = () =>{
        axios.get(dashboardFindAll)
        .then((response) => {
          console.log("images name == ",response);
          setTableData(response.data)
        })
        .catch((err) => console.log(err))
      }

      useEffect(() =>{
        fetchData()
      },[])




// =================UPDATE DATA API CODE===============================
const handleEdit = (id) => {
    const selectedItem = tableData.find(item => item.id === id);
    setFormData({
        id: id,
        file: selectedItem.file, 
        fileName: selectedItem.image
    });
    setSelectedItemId(id);
    setSelectedFileName(selectedItem.image);

    handleOpen1();
};


const handleUpdate = (event) => {
    event.preventDefault();
    const { id, file } = formData;

      // Check if file size is greater than 1MB
      if (file && file.size > 1024 * 1024) {
        toast.error('File size exceeds 1MB limit!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return; 
      }

    const formDataToUpdate = new FormData();
    formDataToUpdate.append('file', file);


    axios.put(`${dashboardUpdate}/${id}`, formDataToUpdate)
        .then(response => {
            if (response.data == "UPDATE") {
                toast.success('Data Updated Successfully!!', {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    });
                    handleClose1();
                    fetchData();
            }
            else if (response.data == "NOTFOUND") {
                toast.warn('Data Not Found!!', {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    });
                fetchData();
                handleClose1();
            }
            handleClose1();
        })
        .catch(error => {
            console.error('Error:', error);
        });
};


// ============================================================================
   


    return (
      <>
          <div className='row m-0' style={{borderRadius:"5px",padding:"10px 15px",display:"flex",justifyContent:"space-between",alignItems:"center",backgroundColor:"#4d6d7c",color:"white"}}>
            <div className="col-6" style={{fontSize:"18px"}}>
                <span>Dashboard</span>
            </div>
            <div className="col-6 text-end">
                <ProfilePage />
                
            </div>

        </div>

        <div className="row m-0 mt-5">
            <div className="col-12 p-0">
                    <button style={{border:"none",backgroundColor:"#4d6d7c",borderRadius:"5px",padding:"10px",color:"white"}} onClick={handleOpen}>Add New</button>
            </div>

            <div className="col-12 p-0 pt-2">
            <TableContainer component={Paper} style={{boxShadow:"1px 1px 5px",width:"100%"}}>
                <Table aria-label="simple table">
                    <TableHead style={{backgroundColor:"#7aa7bc"}}>
                    <TableRow>
                        <TableCell>SR.NO</TableCell>
                        <TableCell align="left">BANNER IMG</TableCell>
                        <TableCell align="center">EDIT</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {tableData.map((row,index) => (
                        <TableRow
                        key={row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">
                            {index+1}
                        </TableCell>

                        <TableCell align="left">
                         {row.image && <img src={`${accessImage}${row.image}`} alt="img" style={{ width: "100px", height: "80px",border:"1px solid" }} />}
                      </TableCell>

                        {/* <TableCell align="left">{row.image}</TableCell> */}
                        <TableCell align="center"><AiFillEdit title='Edit' style={{fontSize:"20px"}} onClick={() => handleEdit(row.id)} /></TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
            </div>

    {/* =============New Entry Modal code================ */}
          
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modalBox">
        <div className="col-12 text-end">
          <AiFillCloseCircle onClick={handleClose} style={{ fontSize: 20, color: "#4d6d7c" }} />
        </div>
        <Typography id="modal-modal-title" style={{ padding: "10px", fontSize: "18px" }}>
        Dashboard
        </Typography>
        <hr />
        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ padding: "10px", fontSize: "14px", borderRadius: "10px" }}>
          <form className="gf-form-group" onSubmit={handleSubmit}>

            <div className="col-12 mt-3 col-md-7">
              <TextField
                type='file'
                className='w-100'
                required
                onChange={handleFileChange}
              />
            </div>
            <div className="col-12 mt-3 col-md-7">
              <Button type="submit" style={{ padding: "8px 15px", borderRadius: "5px", backgroundColor: "#4d6d7c", color: "white" }}>Submit</Button>
            </div>
          </form>
        </Typography>
      </Box>
    </Modal>


  {/* =============Edit Data Modal code================ */}
          
   <Modal
      open={open1}
      onClose={handleClose1}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modalBox">
        <div className="col-12 text-end">
          <AiFillCloseCircle onClick={handleClose1} style={{ fontSize: 20, color: "#4d6d7c" }} />
        </div>
        <Typography id="modal-modal-title" style={{ padding: "10px", fontSize: "18px" }}>
          Dashboard
        </Typography>
        <hr />
        <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ padding: "10px", fontSize: "14px", borderRadius: "10px" }}>
          <form className="gf-form-group" onSubmit={handleUpdate}>
           
            <div className="col-12 mt-3 col-md-7">
              <TextField
                type='file'
                className='w-100'
                required
                onChange={handleFileChange}
              />
               {formData.fileName ? (
                    <span><b>Current File:</b> {formData.fileName}</span>
                  ) : (
                    <span>No file selected</span>
                  )}
            </div>
            <div className="col-12 mt-3 col-md-7">
              <Button type="submit" style={{ padding: "8px 15px", borderRadius: "5px", backgroundColor: "#4d6d7c", color: "white" }}>Update</Button>
            </div>
          </form>
        </Typography>
      </Box>
    </Modal>


        </div>
      </>
    );
};

export default Dashboard;