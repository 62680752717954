import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HeaderPage from '../HeaderPage'
import Footer from '../Footer'
import { accessImage, workshopFindAll } from '../AllAPIs';

const SeminarWorkshop = () => {

  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(workshopFindAll);
        setImages(response.data); 
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchData();
  }, []); 


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
<HeaderPage />

<div className="container-fluid">

    <div className="row p-0 text-center" style={{backgroundColor:"brown",color:"white"}}>
     <div className="col-12 py-3">
        <span> <span style={{color:"#f9a693"}}>Home / Events & Activities </span> / Seminar-Workshop-Guest Lecture </span>
     </div>
   </div>

   <div className="row p-0 py-5 px-md-5"  style={{backgroundColor:"white"}}>
          <div className="col-12 mb-5 text-start px-md-5">
            <h2 style={{ marginBottom: '-10px' }}>Seminar / Workshop/ Guest Lecture</h2>
            <span
              style={{
                color: '#926505',
                textDecoration: 'underline',
                fontWeight: 'bolder',
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </div>

          <div className="col-12 p-0 px-md-5 px-2">
            <ul>
              {images.map((image_url, index) => (
                <li key={index}>
                 
                    <figure>
                      <img className="gallaryImg" src={`${accessImage}${image_url.image_url}`} alt={`image_url ${index + 1}`} />
                    </figure>
                 
                </li>
              ))}
            </ul>
          </div>

        </div>

</div>

<Footer />
    </>
  )
}

export default SeminarWorkshop