import React, { useEffect } from 'react'
import HeaderPage from '../HeaderPage'
import Footer from '../Footer'
import teacher1 from '../../Assets/teacher1.jfif'
import teacher2 from '../../Assets/teacher2.jfif'
import teacher3 from '../../Assets/teacher3.jfif'
import teacher4 from '../../Assets/teacher4.jfif'
import teacher5 from '../../Assets/teacher5.jfif'
import teacher6 from '../../Assets/teacher6.jfif'
import teacher7 from '../../Assets/teacher7.jfif'
import teacher8 from '../../Assets/teacher8.jfif'
import teacher9 from '../../Assets/teacher9.jpeg'
import teacher10 from '../../Assets/teacher10.jfif'

const Faculty = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
<HeaderPage />

<div className="container-fluid">

    <div className="row p-0 text-center" style={{backgroundColor:"brown",color:"white"}}>
     <div className="col-12 py-3">
        {/* <p style={{fontSize:"25px"}}>You are here</p> */}
        <span> <span style={{color:"#f9a693"}}>Home / Academics </span> / Faculty </span>
     </div>
   </div>

          <div className="row p-0 py-5"  style={{backgroundColor:"white"}}>

          <div className="col-12 text-start px-md-5">
            <h2 style={{ marginBottom: '-10px' }}>Faculty</h2>
            <span
              style={{
                color: '#926505',
                textDecoration: 'underline',
                fontWeight: 'bolder',
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </div>

          <section>
            <div class="container3">

              <div class="card">
                <div class="content3">
                  <div class="imgBx3">
                    <img src={teacher7} alt="img" />
                  </div>
                  <div class="contentBx3">
                    <h3>Miss. Baravkar Madam</h3>
                    <div>
                      <span><b>Designation :</b>&nbsp;  Lecturer</span>
                      <span><b>Qualification :</b>&nbsp;   B. Pharm</span>
                      <span><b>Experience :</b>&nbsp;   2 Years</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="content3">
                  <div class="imgBx3">
                    <img src={teacher4} alt="img" />
                  </div>
                  <div class="contentBx3">
                    <h3>Miss hasabe T. S</h3>
                    <div>
                      <span><b>Designation :</b>&nbsp;  Lecturer</span>
                      <span><b>Qualification :</b>&nbsp;   B. Pharm</span>
                      <span><b>Experience :</b>&nbsp;   3 Years</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="content3">
                  <div class="imgBx3">
                    <img src={teacher3} alt="img" />
                  </div>
                  <div class="contentBx3">
                    <h3>Miss. Jadhav Madam</h3>
                    <div>
                      <span><b>Designation :</b>&nbsp;  Asst. Professor</span>
                      <span><b>Qualification :</b>&nbsp;   B. Pharm</span>
                      <span><b>Experience :</b>&nbsp;   1 Years</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="content3">
                  <div class="imgBx3">
                    <img src={teacher6} alt="img" />
                  </div>
                  <div class="contentBx3">
                    <h3>Miss. Mirajkar A.N</h3>
                    <div>
                      <span><b>Designation :</b>&nbsp;  Lecturer</span>
                      <span><b>Qualification :</b>&nbsp;   B. Pharm</span>
                      <span><b>Experience :</b>&nbsp;   1 Years</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="content3">
                  <div class="imgBx3">
                    <img src={teacher5} alt="img" />
                  </div>
                  <div class="contentBx3">
                    <h3>Miss.Patil A. D.</h3>
                    <div>
                      <span><b>Designation :</b>&nbsp;  Asst. Professor</span>
                      <span><b>Qualification :</b>&nbsp;   B. Pharm, M. Pharm</span>
                      <span><b>Experience :</b>&nbsp;   1 Years</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="content3">
                  <div class="imgBx3">
                    <img src={teacher2} alt="img" />
                  </div>
                  <div class="contentBx3">
                    <h3> Mr. Pradeep Chavagonda Patil</h3>
                    <div>
                      <span><b>Designation :</b>&nbsp;  Lecturer</span>
                      <span><b>Qualification :</b>&nbsp;  B.Pharm , M. Pharm</span>
                      <span><b>Experience :</b>&nbsp;   5 Years</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="content3">
                  <div class="imgBx3">
                    <img src={teacher1} alt="img" />
                  </div>
                  <div class="contentBx3">
                    <h3>Miss. Shital Jadhav</h3>
                    <div>
                      <span><b>Designation :</b>&nbsp;  Asst. Professor</span>
                      <span><b>Qualification :</b>&nbsp;   B. Pharm, M. Pharm</span>
                      <span><b>Experience :</b>&nbsp;   4 Years</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="content3">
                  <div class="imgBx3">
                    <img src={teacher8} alt="img" />
                  </div>
                  <div class="contentBx3">
                    <h3>Mr. Dipak Vijay Patil</h3>
                    <div>
                      <span><b>Designation :</b>&nbsp;  Asst. Professor</span>
                      <span><b>Qualification :</b>&nbsp;   B. Pharm, M. Pharm</span>
                      <span><b>Experience :</b>&nbsp;    7 years of Industrial</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="content3">
                  <div class="imgBx3">
                    <img src={teacher9} alt="img" />
                  </div>
                  <div class="contentBx3">
                    <h3>Miss. Poonam Atul Mane Patil</h3>
                    <div>
                      <span><b>Designation :</b>&nbsp;  Lecturer</span>
                      <span><b>Qualification :</b>&nbsp;   B. Pharm</span>
                      <span><b>Experience :</b>&nbsp;   1 Years</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="content3">
                  <div class="imgBx3">
                    <img src={teacher10} alt="img" />
                  </div>
                  <div class="contentBx3">
                    <h3>Miss. Sajida Shyam Dhage</h3>
                    <div>
                      <span><b>Designation :</b>&nbsp;  Asst. Professor</span>
                      <span><b>Qualification :</b>&nbsp;   B. Pharm, M. Pharm</span>
                      <span><b>Experience :</b>&nbsp;   10 Years</span>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </section>
        </div>

</div>

<Footer />
    </>
  )
}

export default Faculty