import React, { useEffect, useState } from 'react';
import './LoginCSS.css';
import { Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { adminLogin } from '../AdminAllAPIs';
import logo from '../../Assets/ekalvya_new.png'
import './LoginCSS.css' ;

function Login() {

  const navigate = useNavigate();
  
  //---------------------------------Disabled back button-------------------------------
  useEffect(() => {
    window.history.pushState(null, null, window.location.href)

    const handleBackButton = (event) => {
      event.preventDefault()
      window.history.forward()
    }

    window.addEventListener('popstate', handleBackButton)

    return () => {
      window.removeEventListener('popstate', handleBackButton)
    }
  }, [])

  

  //==================================================

  useEffect(() => {
    sessionStorage.clear();
  }, []);


  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async (e) => {

    e.preventDefault();

    const saveObj={
      email: email,
      password: password
    };


    try {
      const response = await axios.post(adminLogin,saveObj);
 
      if (response.data.message == "login successful") {

        // Save email and password to session storage
        const userData = `${email}:${password}`;
        sessionStorage.setItem('userData', userData);
    
        
        navigate('/admin/dashboard');

      } else if(response.data === "NOTFOUND"){
        setError('Invalid email or password');
      }
    } catch (error) {
      setError('An error occurred while logging in');
    }
  };


  return (


    <div class="container" id="container">
   
        <div class="form-container sign-in">
            <form onSubmit={handleLogin}>
                <h1 style={{color:"white"}}>Log In</h1>

                <input  type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"/>

                <input type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"/>

                {error && <div style={{ color: 'red' }}>{error}</div>}
                <button type="submit">Log In</button>

            </form>
        </div>

        <div class="toggle-container">
            <div class="toggle">
                <div class="toggle-panel toggle-right">
                <img src={logo} alt="" style={{width:"200px",mixBlendMode:"color-burn"}} />
                    <h1 className='pt-3' style={{color:"#4d6d7c"}}>Hello, Admin!</h1>


                    <span style={{color:"#000"}}>&copy; 2024 | Design & Developed By TechnoAarv Solution</span>
                </div>
            </div>
        </div>

    </div>

  );
}

export default Login;
