import React, { useEffect } from 'react'
import HeaderPage from '../HeaderPage'
import Footer from '../Footer'
import './AcademicCss.css'

const Departments = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
<HeaderPage />

<div className="container-fluid">

    <div className="row p-0 text-center" style={{backgroundColor:"brown",color:"white"}}>
     <div className="col-12 py-3">
        {/* <p style={{fontSize:"25px"}}>You are here</p> */}
        <span> <span style={{color:"#f9a693"}}>Home / Academics </span> / Departments </span>
     </div>
   </div>

        <div className="row p-0 py-5"  style={{backgroundColor:"white"}}>
          <div className="col-12 px-md-5">
            <h2 style={{ marginBottom: '-15px', color: '#000' }}>
              Courses
            </h2>
            <span
              style={{
                color: '#ea826e',
                textDecoration: 'underline',
                fontWeight: 'bolder',
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </div>

          <div className="col-12 col-md-6 my-3 mb-5 mb-md-0 px-md-5">
            <div
              className="px-4  py-5"
              style={{ boxShadow: '1px 1px 5px grey' }}
            >
              <div className="row py-2">
                <h4 style={{ color: '#a52a2a' }}>B. Pharma</h4>
              </div>

              <div className="row p-0 py-2 ">
                <div class="col-12 text-start">
                  <p>Diploma in Pharmacy</p>
                  <p>
                    Eligibility: 10+2 level of education in science stream, from
                    a recognized educational board
                  </p>
                  <p>Duration: 2 years</p>
                  <button className="button2">Know More</button>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 my-3 mb-5 mb-md-0 px-md-5">
            <div
              className="px-4  py-5"
              style={{ boxShadow: '1px 1px 5px grey' }}
            >
              <div className="row py-2">
                <h4 style={{ color: '#a52a2a' }}>D. Pharma</h4>
              </div>

              <div className="row p-0 py-2">
                <div class="col-12 text-start">
                  <p>Bachelor of Pharmacy</p>
                  <p>
                    Eligibility: Candidates should have passed 10+2 in the
                    stream of science from a recognised board of education.
                  </p>
                  <p>Duration: 4 years</p>
                  <button className="button2">Know More</button>
                </div>
              </div>
            </div>
          </div>
        </div>

</div>

<Footer />
    </>
  )
}

export default Departments