import React, { useEffect, useState } from 'react'
import HeaderPage from './HeaderPage'
import Footer from './Footer'
import './HomePageCss.css';
import axios from 'axios';
import { sendMessage } from './AllAPIs';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import { TextField } from '@mui/material';

const ContactPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  
const [email, setEmail] = useState('')
const [name, setName] = useState('')
const [mobileNo, setMobileNo] = useState('')
const [message, setMessage] = useState('')



const handleName = (e) =>{
  setName(e.target.value)
  }
  const handleEmail = (e) =>{
    setEmail(e.target.value)
  }
const handleMobileNumber = (e) =>{
  let input = e.target.value;
  input = input.replace(/\D/g, ''); 
  input = input.slice(0, 10); 
  setMobileNo(input);
  }
const handleMessage = (e) =>{
  setMessage(e.target.value)
  }



    //   ==========SUBMIT DATA API CODE================

    const handleSubmit = (event) => {
      event.preventDefault();
  
      const passObj ={
        fname:name,
        email:email,
        mob_no:mobileNo,
        message:message
      }
  
  
      axios.post(sendMessage, passObj)
        .then(response => {
          if(response.data === "SAVED"){
  
              toast.success('Message Sent Successfully!!', {
                  position: 'top-right',
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                });

                setEmail('');
                setName('');
                setMobileNo('');
                setMessage('');
                
          }
          else if(response.data === "ERROR"){
  
              toast.warn('ERROR !!', {
                  position: 'top-right',
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                });

                
          setEmail('');
          setName('');
          setMobileNo('');
          setMessage('');

          }

  
        })
        .catch(error => {
          console.error('Error:', error);
        });
    };



  return (
    <>
<HeaderPage />

<div className="container-fluid">

    <div className="row p-0 text-center" style={{backgroundColor:"brown",color:"white"}}>
     <div className="col-12 py-3">

        <span> <span style={{color:"#f9a693"}}>Home</span> / Contact Us </span>
     </div>
   </div>

   <div className="row p-0"  style={{backgroundColor:"white"}}>

        <div className="col-12 px-md-5 py-3">
            <h2 style={{ marginBottom: '-15px' }}>
                Keep In Touch With Us
            </h2>
            <span
              style={{
                color: '#926505',
                textDecoration: 'underline',
                fontWeight: 'bolder',
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </div>

          <div className="row p-0 py-5 m-0"  style={{backgroundColor:"white"}}>

              <div className="col-12 col-md-4 pt-3">
                <div className='row m-0 py-3 contactCards'>
                    <div className="col-4" style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                          <div className='Icons'>
                              <i class="fas fa-map-marker-alt"></i>
                          </div>
                    </div>
                    <div className="col-8" style={{display:"flex",alignItems:"start",justifyContent:"center",flexDirection:"column"}}>
                        <p><b>Address :</b></p>
                        <span>
                        Eklavya College of Pharmacy,Tasgaon,
                        Affiliated to DBATU, Recognized by Govt. Of Maharashtra & Approved by PCI (New Delhi), DTE Tasgaon-Manerajuri Road, Vasumbe,Tasgaon. Tal.Tasgaon, Dist.Sangli. 416 312
                        </span>
                    </div>
                </div>
              </div>

              <div className="col-12 col-md-4 pt-3">
                <div className='row m-0 py-3 contactCards'>
                    <div className="col-4" style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                          <div className='Icons'>
                              <i class="fa-solid fa-phone"></i>
                          </div>
                    </div>
                    <div className="col-8" style={{display:"flex",alignItems:"start",justifyContent:"center",flexDirection:"column"}}>
                        <p><b>Contact :</b></p>
                        <span>
                        Mobile No :+91 8329425450,9923701786, 9527078789, 9518724883 <br />
                        phone No :-02346,250701
                        </span>
                    </div>
                </div>
              </div>

              <div className="col-12 col-md-4 pt-3">
                <div className='row m-0 py-3 contactCards'>
                    <div className="col-4" style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                          <div className='Icons'>
                              <i class="fa-solid fa-envelope"></i>
                          </div>
                    </div>
                    <div className="col-8" style={{display:"flex",alignItems:"start",justifyContent:"center",flexDirection:"column"}}>
                        <p><b>Email :</b></p>
                        <span>
                        eklvyacop@gmail.com
                        </span>
                    </div>
                </div>
              </div>

          </div>

          <div className="row m-0 py-5"  style={{backgroundColor:"white"}}>

             <div className="col-12 p-0 col-md-2"></div>

             <div className="col-12 p-4 p-md-5 col-md-8" style={{boxShadow:"1px 1px 5px",backgroundColor:"white"}}>
             <h5> Any Query Do Message ...</h5>
             <form className="gf-form-group pt-2" onSubmit={handleSubmit}>
                <div className="col-12 mt-2">
                  <TextField
                    type='text'
                    className=' w-100'
                    required
                    id="name"
                    label="Name"
                    value={name}
                    onChange={handleName}
                  />
                </div>
                <div className="col-12 mt-3">
                  <TextField
                    type='email'
                    className='w-100'
                    required
                    id="email"
                    label="Email"
                    value={email}
                    onChange={handleEmail}
                  />
                </div>
                <div className="col-12 mt-3">
                  <TextField
                    type='tel'
                    className='w-100'
                    required
                    id="mobile"
                    label="Mobile"
                    inputProps={{
                      pattern: "[0-9]*",
                        maxLength: 10
                      }}
                    value={mobileNo}
                    onChange={handleMobileNumber}
                  />
                </div>

                <div className="col-12 mt-3">
                    <label>Message</label>
                    <textarea id='message' title='Message' style={{width:"100%",border:"1px solid #c0c3c4",borderRadius:"5px",padding:"10px"}} name="postContent" rows={4} cols={40} onChange={handleMessage} value={message}/>
               
                </div>
                <div className="col-12 mt-3 ">
                  <Button type="submit" style={{ padding: "8px 25px", borderRadius: "5px", backgroundColor: "#4d6d7c", color: "white" }}>Submit</Button>
                </div>
             </form>
             </div>

             <div className="col-12 p-0 col-md-2"></div>
          </div>

{/* =========map======================== */}
          <div className="row m-0 p-0">
              <div className="col-12 p-0">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d65922.13508425737!2d74.61879141305563!3d17.03703710137622!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc13e545284da2d%3A0x33d3ff4a949099ff!2sEklavya%20College%20of%20Pharmacy!5e0!3m2!1sen!2sin!4v1709360458661!5m2!1sen!2sin" width="100%" height="400"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
          </div>

   </div>

</div>

<Footer />
    </>
  )
}

export default ContactPage