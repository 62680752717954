// export const BaseIP = 'http://192.168.29.112:8080/';  //local api
export const BaseIP = 'https://eklavya.metrogroupsmg.com/';  //online api


export const noticeBoardFindAll= BaseIP+ "api/notice/findAll" // Notice Board FindAll

export const industrialVisitsFindAll= BaseIP+ "api/facility/findAll" // Industrial Visits FindAll

export const educationTourFindAll= BaseIP+ "api/tour/findAll" // Educational Tour FindAll

export const culturalFindAll= BaseIP+ "api/cultural/findAll" // Cultural FindAll

export const workshopFindAll= BaseIP+ "api/workshop/findAll" // Workshop FindAll

export const sportsFindAll= BaseIP+ "api/gallery/findAll" // Sports FindAll

export const achivementsFindAll= BaseIP+ "api/achievment/findAll" // Achivements FindAll

export const newsEventsFindAll= BaseIP+ "api/news_event/findAll" // News Events FindAll

export const dashboardFindAll= BaseIP+ "api/dashboard/findAll" // News Events FindAll


export const sendMessage= BaseIP+ "api/contact/add" // Contact Page API (send message)

//==download image api (news_event)========
export const downloadNewsEventsImage= BaseIP+ "api/news_event/download-img" // download image api (news_event) (id=params)

//==download image api (notice)========
export const downloadNoticeImage= BaseIP+ "api/notice/download-img" // download image api (notice) (id=params)

export const accessImage= BaseIP+"server/image/"