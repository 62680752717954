import React, { useEffect } from 'react'
import HeaderPage from '../HeaderPage'
import Footer from '../Footer'
import teacher2 from '../../Assets/teacher2.jfif'
import teacher4 from '../../Assets/teacher4.jfif'
import teacher5 from '../../Assets/teacher5.jfif'
import teacher6 from '../../Assets/teacher6.jfif'
// import DPharmabg from '../../Assets/DPharmabg.jpeg'
import DPharmabg from '../../Assets/pharma.jpg'
import './CoursesCss.css'

const Course2 = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
<HeaderPage />

<div className="container-fluid">

  <div className="row p-0 text-center" style={{backgroundColor:"brown",color:"white"}}>
     <div className="col-12 py-3">
        {/* <p style={{fontSize:"25px"}}>You are here</p> */}
        <span> <span style={{color:"#f9a693"}}>Home / Courses </span> / D.Pharma </span>
     </div>
   </div>

   <div className="row p-0" style={{backgroundColor:"#F2F4F4",maxHeight:"600px"}}>
    <div className="col-12 p-0">
      <img src={DPharmabg} alt="img" className='DPharmabg'/>
    </div>
  </div>

  {/* ========================About D Pharma ========================= */}

    <div className="row p-0 py-5" style={{backgroundColor:"#F2F4F4"}}>

  <div className="col-12 px-md-5 py-3">
    <h2 style={{ marginBottom: '-15px' }}>
      About D. Pharma
    </h2>
    <span
      style={{
        color: '#926505',
        textDecoration: 'underline',
        fontWeight: 'bolder',
      }}
    >
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &nbsp;&nbsp;&nbsp;&nbsp;
    </span>
  </div>

  <div className="col-12 px-4 px-md-5">
      <p className='px-md-5'>
      An education in Pharmacy is the study of drug discovery to formulation and evaluation of medicinal substances and their therapeutic uses. Pharmacist may be involved in any and all aspects of drug and medicine use, such as drug discovery, the drug’s development, or its supply to patients. Pharmacist is an integral part of the healthcare industry, and an exciting profession in which to work.
      </p>
      <p className='px-md-5'>
      Graduates of pharmacy degrees work right at the heart of human healthcare, taking on roles relating to the design and development of new treatments, prescription and care management, and advising on the range of medical options available.
      </p>
      <p className='px-md-5'>
      A Bachelor of Pharmacy (abbreviated B.Pharm) is an undergraduate academic degree in the field of pharmacy. The degree is the basic prerequisite for registration to practice as a pharmacist in many countries and its about understanding the properties and impacts of medicines and developing the skills required to counsel patients about their use, pharmacist have the opportunity to improve health and even save lives.
      </p>
      <p className='px-md-5'>
      The Bachelor of Pharmacy degree is popularly known as B-Pharm in India. It is a four-year program with both annual and semester schemes available. Colleges imparting pharmaceutical education (D.Pharm, B.Pharm, M.Pharm and Pharm D) must be approved by the State Government, All Indian Council of Technical Education (AICTE), Pharmacy Council of India (PCI) and affiliated by a university.
      </p>
      <p className='px-md-5'>
      For a student to be eligible for registration as a pharmacist in India, the college from which he/she graduated must be approved by the PCI. B-Pharm is often superseded by M-Pharm and PhD level courses although the minimum qualification required for registration as a pharmacist is D.Pharm.
      </p>
  </div>
    </div>

{/* ========================Eligibility Criteria========================= */}

    <div className="row p-0 py-5"  style={{backgroundColor:"#e2e2e2"}}>

    <div className="col-12 px-md-5 py-3">
      <h2 style={{ marginBottom: '-15px' }}>
          Eligibility Criteria
      </h2>
      <span
        style={{
          color: '#926505',
          textDecoration: 'underline',
          fontWeight: 'bolder',
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
    </div>

    <div className="col-12 px-md-5 px-3">
        <p className='px-md-5'>The admission process is governed by by Hon. Directorate of Technical Education, Maharashtra State, Mumbai. The vacant seats after Centralized Admission Procedure (CAP) will be filled at Institute level according to merit & as per guidelines of the Hon. Directorate of Technical Education, Maharashtra State, Mumbai.</p>
    </div>

    <div className="row px-2 m-0 px-md-5 py-3">

    <div className="col-12 p-2" style={{backgroundColor:"#a52a2a",}}>
      <div className="p-4 border text-start text-white">
        <span>1) The Candidate should be an India National</span> <br />
        <span>2) For the admission to first year of Diploma course in pharmacy, the candidate should have passed higher secondary certificate H.S.C. (std.XII) Examination of Maharashtra State Board of or its equivalent examination with at least 35% marks in aggregate with Physic, Chemistry, Biology Mathematics and English.</span> <br />
        <span>3) Other than Maharashtra State Candidates shall be eligible for institute quota only.</span> <br />
        <span>4) NRI/OCI/PIO, Children of Indian workers in the Gulf countries, Foreign National- i) Passed higher secondary certificate H.S.C. (std.XII) Examination with Physics & Chemistry as compulsory subject along with one of the subject Mathematics or Biology.(Botany & Zoology) ii) Any other criteria declared from time to time by the appropriate authority as defined under the Act.</span> <br />
      </div>
    </div>

    </div>
    </div>

{/* ========================Fees Structure========================= */}

      <div className="row p-0 py-5" style={{backgroundColor:"#F2F4F4 ",boxShadow:"1px 1px 2px"}}>

      <div className="col-12 px-md-5 py-3">
        <h2 style={{ marginBottom: '-15px' }}>
            Program Fees
        </h2>
        <span
          style={{
            color: '#926505',
            textDecoration: 'underline',
            fontWeight: 'bolder',
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </div>


      <div className="col-12 tableData px-md-5 px-2">
      <table className='table'>
      <tr>
      <th>Category</th>
      <th>Open</th>
      <th>OBC/SEBC/EBC/EWS</th>
      <th>SC/ST</th>
      <th>SBC/VJ-NT/DT</th>
      </tr>
      <tr>
      <td>D. Pharm</td>
      <td>65,000/-</td>
      <td>34,908/-</td>
      <td>0</td>
      <td>4815/-</td>
      </tr>
      </table>

      <p style={{fontSize:"12px",paddingTop:"10px",color:"red"}}>Fees for academic year 2024-2025 may change as per Fee Regulating Authority Approval*</p>
      </div>

      </div>

{/* ========================Our Faculty================== */}

    <div className="row p-0 py-5"  style={{backgroundColor:"white"}}>

    <div className="col-12 text-center">
    <h2 className=''>OUR FACULTY</h2>
    </div>
    <div class="row p-0 container2">

    <div class="col-12 col-md-3 box">
    <div class="imgBox2">
    <img src={teacher2} alt=""/>
    </div>
    <div class="content2">
    <h2>Mr. Pradeep Chavagonda<br/>
    <span>Lecturer</span></h2>
    </div>
    </div>

    <div class="col-12 col-md-3 box">

    <div class="imgBox2">
    <img src={teacher4} alt=""/>
    </div>

    <div class="content2">
    <h2>Miss. Tejashri Suresh Hasabe<br/>
    <span>Lecturer</span></h2>
    </div>
    </div>

    <div class="col-12 col-md-3 box">
    <div class="imgBox2">
    <img src={teacher5} alt=""/>
    </div>
    <div class="content2">
    <h2>Miss Aishwarayadevi Patil<br/>
    <span>Asst. Professor</span></h2>
    </div>
    </div>

    <div class="col-12 col-md-3 box">

    <div class="imgBox2">
    <img src={teacher6} alt=""/>
    </div>

    <div class="content2">
    <h2>Miss Aishwaraya Namadev Mirajkar<br/>
    <span>Lecturer</span></h2>
    </div>
    </div>

    </div>
    </div>


</div>

<Footer />
    </>
  )
}

export default Course2