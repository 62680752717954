import React, { useEffect } from 'react'
import HeaderPage from '../HeaderPage'
import Footer from '../Footer'
import principal from '../../Assets/autho3.jfif'
import teacher1 from '../../Assets/teacher1.jfif'
import teacher2 from '../../Assets/teacher2.jfif'
import teacher3 from '../../Assets/teacher3.jfif'
import BPharmabg from '../../Assets/BPharmabg.jpg'
import './CoursesCss.css'

const Course1 = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <>
<HeaderPage />

<div className="container-fluid">

  <div className="row p-0 text-center" style={{backgroundColor:"brown",color:"white"}}>
     <div className="col-12 py-3">
        {/* <p style={{fontSize:"25px"}}>You are here</p> */}
        <span> <span style={{color:"#f9a693"}}>Home / Courses </span> / B.Pharma </span>
     </div>
   </div>

<div className="row p-0" style={{backgroundColor:"#F2F4F4 "}}>
    <div className="col-12 p-0">
      <img src={BPharmabg} alt="img" className='BPharmabg' style={{width:'100%',borderRadius:"0px 0px 0px 130px",}}/>
    </div>
</div>

{/* ========================About B Pharma ========================= */}
<div className="row p-0 py-5" style={{backgroundColor:"#F2F4F4 "}}>

                <div className="col-12 px-md-5 py-3">
                  <h2 style={{ marginBottom: '-15px' }}>
                     About B. Pharma
                  </h2>
                  <span
                    style={{
                      color: '#926505',
                      textDecoration: 'underline',
                      fontWeight: 'bolder',
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </div>

                <div className="col-12 px-4 px-md-5">
                    <p className='px-md-5'>
                    An education in Pharmacy is the study of drug discovery to formulation and evaluation of medicinal substances and their therapeutic uses. Pharmacist may be involved in any and all aspects of drug and medicine use, such as drug discovery, the drug’s development, or its supply to patients. Pharmacist is an integral part of the healthcare industry, and an exciting profession in which to work.
                    </p>
                    <p className='px-md-5'>
                    Graduates of pharmacy degrees work right at the heart of human healthcare, taking on roles relating to the design and development of new treatments, prescription and care management, and advising on the range of medical options available.
                    </p>
                    <p className='px-md-5'>
                    A Bachelor of Pharmacy (abbreviated B.Pharm) is an undergraduate academic degree in the field of pharmacy. The degree is the basic prerequisite for registration to practice as a pharmacist in many countries and its about understanding the properties and impacts of medicines and developing the skills required to counsel patients about their use, pharmacist have the opportunity to improve health and even save lives.
                    </p>
                    <p className='px-md-5'>
                    The Bachelor of Pharmacy degree is popularly known as B-Pharm in India. It is a four-year program with both annual and semester schemes available. Colleges imparting pharmaceutical education (D.Pharm, B.Pharm, M.Pharm and Pharm D) must be approved by the State Government, All Indian Council of Technical Education (AICTE), Pharmacy Council of India (PCI) and affiliated by a university.
                    </p>
                    <p className='px-md-5'>
                    For a student to be eligible for registration as a pharmacist in India, the college from which he/she graduated must be approved by the PCI. B-Pharm is often superseded by M-Pharm and PhD level courses although the minimum qualification required for registration as a pharmacist is D.Pharm.
                    </p>
                </div>
</div>

{/* ========================Eligibility Criteria========================= */}
    <div className="row p-0 py-5" style={{backgroundColor:"#e2e2e2",}}>

              <div className="col-12 px-md-5 py-3">
                  <h2 style={{ marginBottom: '-15px' }}>
                      Eligibility Criteria
                  </h2>
                  <span
                    style={{
                      color: '#926505',
                      textDecoration: 'underline',
                      fontWeight: 'bolder',
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </div>

            <div className="col-12 px-md-5 px-3">
                    <p className='px-md-5'>Centralized on-line admission process for B. Pharmacy (First Year), Direct Second Year B. Pharmacy. is governed by State Common Entrance Test Cell, Directorate of Technical Education Maharashtra every year. To participate in admission process students must have to appear for MHT CET 2024.</p>
            </div>

            <div className="row px-2 m-0 px-md-5 py-3">
                <div className="col-12 px-md-5">
                  <h6>ELIGIBILITY CRITERIA FOR B. PHARM. (INTAKE 60)</h6>
                </div>

                <div className="col-12 col-md-4 p-2" style={{backgroundColor:"#a52a2a",}}>
                  <div className="p-4 border text-start text-white" style={{height:"465px"}}>
                    <p><u>Maharashtra State Candidature Candidates</u> </p>
                    <span>For First Year B. Pharm</span> <br />
                    <span>1) The candidate should be an Indian National</span> <br />
                    <span>2) General Category: Passed HSC or its equivalent examination with Physics and Chemistry as compulsory subjects along with one of the Mathematics or Biology and obtained at least 45% marks (i.e. 135 marks / out of 300 marks)</span> <br />
                    <span>3) Reserved Category: at least 40% marks (i.e. 120 marks / out of 300 marks)</span> <br />
                    <span>4) should have appeared and Obtained Score in MHT- CET-2024</span> <br />
                  </div>
                </div>

                <div className="col-12 col-md-4 p-2" style={{backgroundColor:"#a52a2a",}}>
                  <div className="p-4 border text-start text-white" style={{height:"465px"}}>
                    <p><u>All India Candidature Candidates</u> </p>
                    <span>1) Candidate should obtain non zero positive score in NEET-2024 or the candidate should have appeared in all the subjects in MHT-CET 2024 and should obtain non zero score in CET-2024 conducted by the Competent Authority. However, preference shall be given to the candidate obtaining non zero positive score in NEET over the candidates who obtained non zero score in CET.</span> 
                  </div>
                </div>

                <div className="col-12 col-md-4 p-2" style={{backgroundColor:"#a52a2a",}}>
                  <div className="p-4 border text-start text-white" style={{height:"465px"}}>
                    <p><u>Eligibility Criteria For Direct Second Year B. Pharm</u> </p>
                    <span>General Category: Passed Diploma Course in Pharmacy with at least 45 % marks from an All India Council for Technical Education or Pharmacy Council of India or Central or State Government approved Institution or its equivalent.</span> <br /><br />
                    <span>Reserved Category: Passed Diploma Course in Pharmacy with at least (at least 40 % marks) in case of candidates of Backward class categories and Persons with disability belonging to Maharashtra State only from an All India Council for Technical Education or Pharmacy Council of India or Central or State Government approved Institution or its equivalent</span> <br />
                   
                  </div>
                </div>
            </div>
    </div>

{/* ========================Fees Structure========================= */}
      <div className="row p-0 py-5" style={{backgroundColor:"#F2F4F4 ",boxShadow:"1px 1px 2px"}}>

            <div className="col-12 px-md-5 py-3">
                  <h2 style={{ marginBottom: '-15px' }}>
                      Program Fees
                  </h2>
                  <span
                    style={{
                      color: '#926505',
                      textDecoration: 'underline',
                      fontWeight: 'bolder',
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </div>


          <div className="col-12 tableData px-md-5 px-2">
            <table className='table'>
              <tr>
                <th>Category</th>
                <th>Open</th>
                <th>OBC/SEBC/EBC/EWS</th>
                <th>SC/ST</th>
                <th>SBC/VJ-NT/DT</th>
              </tr>
              <tr>
                <td>B. Pharm</td>
                <td>70,000/-</td>
                <td>38182/-</td>
                <td>0</td>
                <td>6364/-</td>
              </tr>
            </table>

            <p style={{fontSize:"12px",paddingTop:"10px",color:"red"}}>Fees for academic year 2024-2025 may change as per Fee Regulating Authority Approval*</p>
          </div>

      </div>
    {/* ========================Our Faculty================== */}

        <div className="row p-0 py-5" style={{backgroundColor:"#fff"}}>

            <div className="col-12 text-center">
            <h2 className=''>OUR FACULTY</h2>
            </div>
            <div class="row p-0 container2">

            <div class="col-12 col-md-3 box">

            <div class="imgBox2">
            <img src={principal} alt=""/>
            </div>

            <div class="content2">
            <h2>Mr. Prasad Vasantrao Patrekar<br/>
            <span>Principal</span></h2>
            </div>
            </div>

            <div class="col-12 col-md-3 box">

            <div class="imgBox2">
            <img src={teacher1} alt=""/>
            </div>

            <div class="content2">
            <h2>Miss. Shital Tanaji jadhav<br/>
            <span>Asst. Professor</span></h2>
            </div>
            </div>

            <div class="col-12 col-md-3 box">
            <div class="imgBox2">
            <img src={teacher2} alt=""/>
            </div>
            <div class="content2">
            <h2>Mr. Pradeep Chavagonda<br/>
            <span>Lecturer</span></h2>
            </div>
            </div>

            <div class="col-12 col-md-3 box">

            <div class="imgBox2">
            <img src={teacher3} alt=""/>
            </div>

            <div class="content2">
            <h2>Miss. Vinaya Vijay Jadhav<br/>
            <span>Asst. Professor</span></h2>
            </div>
            </div>

            </div>
        </div>

</div>

<Footer />
    </>
  )
}

export default Course1