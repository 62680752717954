// import './App.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { Route, Routes } from 'react-router-dom';

// import HeaderPage from './AllComponents/HeaderPage';
// import HomePage from './AllComponents/HomePage';
// import AboutUsPage from './AllComponents/AboutUsPage';
// import ContactPage from './AllComponents/ContactPage';
// import Course1 from './AllComponents/CourseFolder/Course1';
// import Course2 from './AllComponents/CourseFolder/Course2';
// import AdmissionPage from './AllComponents/AdmissionPage';
// import Departments from './AllComponents/AcademicsFolder/Departments';
// import Faculty from './AllComponents/AcademicsFolder/Faculty';
// import Infrastructure from './AllComponents/AcademicsFolder/Infrastructure';
// import Library from './AllComponents/AcademicsFolder/Library';
// import Syllabus from './AllComponents/AcademicsFolder/Syllabus';
// import Alumni from './AllComponents/Alumni';
// import Sports from './AllComponents/EventActivitiesFolder/Sports';
// import Culture from './AllComponents/EventActivitiesFolder/Culture';
// import IndustrialVisits from './AllComponents/EventActivitiesFolder/IndustrialVisits';
// import EducationalTour from './AllComponents/EventActivitiesFolder/EducationalTour';
// import SeminarWorkshop from './AllComponents/EventActivitiesFolder/SeminarWorkshop';
// import Grievance from './AllComponents/StudentCornerFolder/Grievance';
// import AntiRagging from './AllComponents/StudentCornerFolder/AntiRagging';
// import Scholarship from './AllComponents/StudentCornerFolder/Scholarship';
// import Achivements from './AllComponents/StudentCornerFolder/Achivements';

// function App() {
//   return (
//    <>
// <Routes>

//     <Route  element={<HeaderPage />}/>

//     <Route path="" element={<HomePage />}/>
//     <Route path="/aboutUs" element={<AboutUsPage />}/>

//     {/* ========Courses========== */}

//     <Route path="/course1" element={<Course1 />}/>
//     <Route path="/course2" element={<Course2 />}/>

//     <Route path="/admission" element={<AdmissionPage />}/>

//   {/* ========Academics========== */}

//     <Route path="/academics1" element={<Departments />}/>
//     <Route path="/academics2" element={<Faculty />}/>
//     <Route path="/academics3" element={<Syllabus />}/>
//     <Route path="/academics4" element={<Infrastructure />}/>
//     <Route path="/academics5" element={<Library />}/>

//     <Route path="/alumni" element={<Alumni />}/>

//     {/* ========Events & Activities========== */}

//     <Route path="/events1" element={<SeminarWorkshop />}/>
//     <Route path="/events2" element={<IndustrialVisits />}/>
//     <Route path="/events3" element={<EducationalTour />}/>
//     <Route path="/events4" element={<Culture />}/>
//     <Route path="/events5" element={<Sports />}/>

//       {/* ========Student Corner========== */}

//     <Route path="/students1" element={<Grievance />}/>
//     <Route path="/students2" element={<AntiRagging />}/>
//     <Route path="/students3" element={<Scholarship />}/>
//     <Route path="/students4" element={<Achivements />}/>

//     <Route path="/contactUs" element={<ContactPage />}/>

//   </Routes>
//    </>
//   );
// }

// export default App;

import React, { useEffect, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

// Import components from the first snippet
import HeaderPage from './AllComponents/HeaderPage'
import HomePage from './AllComponents/HomePage'
import AboutUsPage from './AllComponents/AboutUsPage'
import ContactPage from './AllComponents/ContactPage'
import Course1 from './AllComponents/CourseFolder/Course1'
import Grievance from './AllComponents/StudentCornerFolder/Grievance'
import AntiRagging from './AllComponents/StudentCornerFolder/AntiRagging'
import Scholarship from './AllComponents/StudentCornerFolder/Scholarship'
import Course2 from './AllComponents/CourseFolder/Course2'
import AdmissionPage from './AllComponents/AdmissionPage'
import Departments from './AllComponents/AcademicsFolder/Departments'
import Faculty from './AllComponents/AcademicsFolder/Faculty'
import Infrastructure from './AllComponents/AcademicsFolder/Infrastructure'
import Library from './AllComponents/AcademicsFolder/Library'
import Syllabus from './AllComponents/AcademicsFolder/Syllabus'
import Alumni from './AllComponents/Alumni'

import SeminarWorkshop from './AllComponents/EventActivitiesFolder/SeminarWorkshop'
import IndustrialVisits from './AllComponents/EventActivitiesFolder/IndustrialVisits'
import EducationalTour from './AllComponents/EventActivitiesFolder/EducationalTour'
import Culture from './AllComponents/EventActivitiesFolder/Culture'
import Sports from './AllComponents/EventActivitiesFolder/Sports'

import Dashboard from './AllComponents/AdminComponents/Dashboard'
import AchievementsAdmin from './AllComponents/AdminComponents/AchievementsAdmin'
import CulturalAdmin from './AllComponents/AdminComponents/CulturalAdmin'
import NoticeBoard from './AllComponents/AdminComponents/NoticeBoard'
import NewsEvents from './AllComponents/AdminComponents/NewsEvents'
import IndustrialVisitsAdmin from './AllComponents/AdminComponents/IndustrialVisitsAdmin'
import EducationalTourAdmin from './AllComponents/AdminComponents/EducationalTourAdmin'
import Workshop from './AllComponents/AdminComponents/Workshop'
import SportsAdmin from './AllComponents/AdminComponents/SportsAdmin'
import ContactUs from './AllComponents/AdminComponents/ContactUs'
import ProfilePage from './AllComponents/AdminComponents/ProfilePage'
import Sidebar from './AllComponents/AdminComponents/Sidebar'
import Login from './AllComponents/AdminComponents/Login'
import Achivements from './AllComponents/StudentCornerFolder/Achivements'

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(true)

  // Function to handle logout
  const handleLogout = () => {
    setIsLoggedIn(false)
    sessionStorage.removeItem('isLoggedIn')
  }

  return (
    <Routes>
      <Route element={<HeaderPage />} />
      <Route path="/" element={<HomePage />} />
      <Route path="/aboutUs" element={<AboutUsPage />} />
      <Route path="/course1" element={<Course1 />} />
      <Route path="/course2" element={<Course2 />} />
      <Route path="/admission" element={<AdmissionPage />} />
      <Route path="/academics1" element={<Departments />} />
      <Route path="/academics2" element={<Faculty />} />
      <Route path="/academics3" element={<Syllabus />} />
      <Route path="/academics4" element={<Infrastructure />} />
      <Route path="/academics5" element={<Library />} />
      <Route path="/alumni" element={<Alumni />} />
      <Route path="/events1" element={<SeminarWorkshop />} />
      <Route path="/events2" element={<IndustrialVisits />} />
      <Route path="/events3" element={<EducationalTour />} />
      <Route path="/events4" element={<Culture />} />
      <Route path="/events5" element={<Sports />} />

      <Route path="/students1" element={<Grievance />} />
      <Route path="/students2" element={<AntiRagging />} />
      <Route path="/students3" element={<Scholarship />} />
      <Route path="/students4" element={<Achivements />} />
      <Route path="/contactUs" element={<ContactPage />} />

      <Route path="/login" element={<Login />} />

      <Route path="/*" element={isLoggedIn ? (
            <Sidebar onLogout={handleLogout}>
              <Routes>
                <Route path="/admin/dashboard" element={<Dashboard />} />
                <Route path="admin/dashboard" element={<Dashboard />} />
                <Route path="admin/cultural" element={<CulturalAdmin />} />
                <Route path="admin/notice" element={<NoticeBoard />} />
                <Route path="admin/news" element={<NewsEvents />} />
                <Route path="admin/visit" element={<IndustrialVisitsAdmin />}/>
                <Route path="admin/tour" element={<EducationalTourAdmin />} />
                <Route path="admin/achievements" element={<AchievementsAdmin />}/>
                <Route path="admin/workshop" element={<Workshop />} />
                <Route path="admin/sports" element={<SportsAdmin />} />
                <Route path="admin/contactUs" element={<ContactUs />} />
                <Route element={<ProfilePage />} />
              </Routes>
            </Sidebar>
          ) : (
            <Navigate to="/login" />
          )
        }
      />
    </Routes>
  )
}

export default App
