// export const BaseIP = 'http://192.168.29.112:8080/';  //local api
export const BaseIP = 'https://eklavya.metrogroupsmg.com/';  //online api

export const adminLogin= BaseIP+ "api/admin/login" // Admin login 

//Notice Board API's==========
export const noticeBoardCreate= BaseIP+ "api/notice/add" // Notice Board Create
export const noticeBoardUpdate= BaseIP+ "api/notice/update" //Notice Board Update (params= id)
export const noticeBoardDelete= BaseIP+ "api/notice/delete" // Notice Board Delete (params= id)
export const noticeBoardFindAll= BaseIP+ "api/notice/findAll" // Notice Board FindAll


//Industrial Visits API's==========
export const industrialVisitsCreate= BaseIP+ "api/facility/add" // Industrial Visits Create
export const industrialVisitsUpdate= BaseIP+ "api/facility/update" //Industrial Visits Update (params= id)
export const industrialVisitsDelete= BaseIP+ "api/facility/delete" // Industrial Visits Delete (params= id)
export const industrialVisitsFindAll= BaseIP+ "api/facility/findAll" // Industrial Visits FindAll

//Educational Tour API's==========
export const educationalTourCreate= BaseIP+ "api/tour/add" // Educational Tour Create
export const educationalTourUpdate= BaseIP+ "api/tour/update" //Educational Tour Update (params= id)
export const educationalTourDelete= BaseIP+ "api/tour/delete" // Educational Tours Delete (params= id)
export const educationalTourFindAll= BaseIP+ "api/tour/findAll" // Educational Tour FindAll


//Cultural API's==========
export const culturalCreate= BaseIP+ "api/cultural/add" // Cultural Create
export const culturalUpdate= BaseIP+ "api/cultural/update" //Cultural Update (params= id)
export const culturalDelete= BaseIP+ "api/cultural/delete" // Cultural Delete (params= id)
export const culturalFindAll= BaseIP+ "api/cultural/findAll" // Cultural FindAll


//Workshop API's==========
export const workshopCreate= BaseIP+ "api/workshop/add" // Workshop Create
export const workshopUpdate= BaseIP+ "api/workshop/update" //Workshop Update (params= id)
export const workshopDelete= BaseIP+ "api/workshop/delete" // Workshop Delete (params= id)
export const workshopFindAll= BaseIP+ "api/workshop/findAll" // Workshop FindAll

//Sports API's==========
export const sportsCreate= BaseIP+ "api/gallery/add" // Sports Create
export const sportsUpdate= BaseIP+ "api/gallery/update" //Sports Update (params= id)
export const sportsDelete= BaseIP+ "api/gallery/delete" // Sports Delete (params= id)
export const sportsFindAll= BaseIP+ "api/gallery/findAll" // Sports FindAll

//Achivements API's==========
export const achivementsCreate= BaseIP+ "api/achievment/add" // Achivements Create
export const achivementsUpdate= BaseIP+ "api/achievment/update" //Achivements Update (params= id)
export const achivementsDelete= BaseIP+ "api/achievment/delete" // Achivements Delete (params= id)
export const achivementsFindAll= BaseIP+ "api/achievment/findAll" // Achivements FindAll


// News Events API's==========
export const newsEventsCreate= BaseIP+ "api/news_event/add" // News Events Create
export const  newsEventsUpdate= BaseIP+ "api/news_event/update" //News Events Update (params= id)
export const  newsEventsDelete= BaseIP+ "api/news_event/delete" // News Events Delete (params= id)
export const newsEventsFindAll= BaseIP+ "api/news_event/findAll" // News Events FindAll

// Dashboard API's==========
export const dashboardCreate= BaseIP+ "api/dashboard/add" // News Events Create
export const  dashboardUpdate= BaseIP+ "api/dashboard/update" //News Events Update (params= id)
export const dashboardFindAll= BaseIP+ "api/dashboard/findAll" // News Events FindAll

//========Admin change password API
export const changePassword= BaseIP+ "api/admin/change-password" // Admin change password api


//========Contact API
export const contactFindAll= BaseIP+ "api/contact/findAll" // Contact FindAll API

//image path==========================
export const accessImage= BaseIP+"server/image/"