import React, { useState } from 'react';
import { FaBars }from "react-icons/fa";
import { MdDashboard } from "react-icons/md";
import { BiNews } from "react-icons/bi";
import { BiTrip } from "react-icons/bi";
import { GiCultist } from "react-icons/gi";
import { AiFillExperiment } from "react-icons/ai";
import { MdSportsVolleyball } from "react-icons/md";
import { RiContactsFill } from "react-icons/ri";
import { NavLink } from 'react-router-dom';
import { AiFillContainer } from "react-icons/ai";
import { GiAchievement } from "react-icons/gi";
import EklavyaLogo  from "../../Assets/Ekalvya LOGO JPEG.jpg";
import './SidebarCSS.css' ;


const Sidebar = ({children}) => {
    const[isOpen ,setIsOpen] = useState(false);
    const toggle = () => setIsOpen (!isOpen);

    const menuItem=[
        {
            path:"/admin/dashboard",
            name:"Dashboard",
            icon:<MdDashboard />,
            title: 'Dashboard',
        },
        {
            path:"admin/news",
            name:"News & Events",
            icon:<BiNews />
        },
        {
            path:"admin/notice",
            name:"Notice Board",
            icon:<AiFillContainer   />
        },
        {
            path:"admin/visit",
            name:"Industrial Visit",
            icon:<BiTrip  />
        },
        {
            path:"admin/tour",
            name:"Educational Tour",
            icon:<BiTrip  />
        },
        {
            path:"admin/achievements",
            name:"Achievements",
            icon:<GiAchievement   />
        },
        {
            path:"admin/cultural",
            name:"Cultural",
            icon:<GiCultist  />
        },
        {
            path:"admin/workshop",
            name:"Workshop",
            icon:<AiFillExperiment   />
        },
        {
            path:"admin/sports",
            name:"Sports",
            icon:<MdSportsVolleyball />
        },
        {
            path:"admin/contactUs",
            name:"Contact Us",
            icon:<RiContactsFill />
        }
    ]
    
    return (
        <div className="container-fluid d-flex p-0 sideBar">
           <div style={{width: isOpen ? "270px" : "50px"}} className="sidebar">
               <div className="top_section">
                   <h1 style={{display: isOpen ? "block" : "none"}} className="logo">
                        <img src={EklavyaLogo} alt="" style={{width:"190px",height:"110px"}} />
                   </h1>
                    <div style={{marginLeft: isOpen ? "30px" : "0px"}} className="bars">
                       <FaBars onClick={toggle}/>
                   </div>
               </div>
               {
                   menuItem.map((item, index)=>(
                       <NavLink to={item.path} key={index} className="link" activeclassName="active">
                           <div className="icon">{item.icon}</div>
                           <div style={{display: isOpen ? "block" : "none"}} className="link_text">{item.name}</div>
                       </NavLink>
                   ))
               }
           </div>
           <div className='main'>{children}</div>
        </div>
    );
};

export default Sidebar;