import React, { useEffect } from 'react'
import HeaderPage from '../HeaderPage'
import Footer from '../Footer'
import AntiRaggingbg from '../../Assets/antiRagging2.jpg'
import './StudentCornerCss.css'
import Table from 'react-bootstrap/Table';

const AntiRagging = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
<HeaderPage />

<div className="container-fluid">

    <div className="row p-0 text-center" style={{backgroundColor:"brown",color:"white"}}>
     <div className="col-12 py-3">
        {/* <p style={{fontSize:"25px"}}>You are here</p> */}
        <span> <span style={{color:"#f9a693"}}>Home / Student Corner </span> / Anti Ragging Commity</span>
     </div>
   </div>

   <div className="row p-0"  style={{backgroundColor:"white"}}>
    <div className="col-12 p-0">
      <img src={AntiRaggingbg} alt="img" height={450} className='AntiRaggingbg'/>
    </div>
  </div>

   <div className="row p-0 py-5"  style={{backgroundColor:"white"}}>

                <div className="col-12 px-md-5 py-3">
                  <h2 style={{ marginBottom: '-15px' }}>
                    Anti Ragging Commity
                  </h2>
                  <span
                    style={{
                      color: '#926505',
                      textDecoration: 'underline',
                      fontWeight: 'bolder',
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </div>

{/* ==========table D pharmacy=========== */}

                <div className="col-12 px-3 px-md-5">
                  <h4 className='px-md-5'>Anti Ragging Commity (D. Pharmacy)</h4>
                  <h5 className='px-md-5'>(Academic Year 2024-25)</h5>
                </div>

                <div className="col-12 px-md-5">
                  <div className='px-md-5'>
                      <Table className='tableAntiRagging' responsive="sm" style={{border:"1px solid #D5D8DC",padding:"10px"}}>
                        <thead>
                          <tr>
                            <th>Sr.No</th>
                            <th>Name</th>
                            <th>Designation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className='tableRow'>
                            <td>1</td>
                            <td> Mr. Patrekar Prasad Vasantrao</td>
                            <td>Principal</td>
                          </tr>
                          <tr className='tableRow'>
                            <td>2</td>
                            <td>Ms.Mirajkar Aishwarya Namdev</td>
                            <td>Lecturer</td>
                          </tr>
                          <tr className='tableRow'>
                            <td>3</td>
                            <td> Ms.Patil Aishwaryadevi  Dattajirao</td>
                            <td>Lecturer</td>
                          </tr>
                          <tr className='tableRow'>
                            <td>4</td>
                            <td> Ms. Hasbe Tejashri Suresh</td>
                            <td>Lecturer</td>
                          </tr>
                          <tr className='tableRow'>
                            <td>5</td>
                            <td> Mr. Kumbhar Ashok Jambu</td>
                            <td>Office suprendent</td>
                          </tr>
                          <tr className='tableRow'>
                            <td>6</td>
                            <td> Mr. Mulla Miraso Gulab</td>
                            <td>Lab Attendant</td>
                          </tr>
                          <tr className='tableRow'>
                            <td>7</td>
                            <td>Adv.Kumbhar Tukaram R.</td>
                            <td>Lawyer</td>
                          </tr>
                          <tr className='tableRow'>
                            <td>8</td>
                            <td> Mr.Sawant Sandeep Vitthal</td>
                            <td>C.R. DCP-II</td>
                          </tr>
                          <tr className='tableRow'>
                            <td>9</td>
                            <td>Ms.Patil Vaishnavi Vitthal</td>
                            <td>L.R.DCP-II</td>
                          </tr>
                          <tr className='tableRow'>
                            <td>10</td>
                            <td> Mr.Kulkarni Sarvesh Niketan</td>
                            <td>C.R.DCP-I</td>
                          </tr>
                          <tr className='tableRow'>
                            <td>11</td>
                            <td>Ms.Kadam Pratiksha Trimbak</td>
                            <td>L.R.DCP-I</td>
                          </tr>
                          <tr className='tableRow'>
                            <td>12</td>
                            <td> Mr.  Niketan Narendre Kulkarni</td>
                            <td>Parent Representive</td>
                          </tr>
                          <tr className='tableRow'>
                            <td>13</td>
                            <td>Mr. Vitthal Hindurao Patil</td>
                            <td>Parent Representive</td>
                          </tr>
                          
                        </tbody>
                        </Table>
                  </div>
                </div>

{/* ==========table B pharmacy=========== */}

                <div className="col-12 px-3 px-md-5 pt-5">
                  <h4 className='px-md-5'>Anti Ragging Commity (B. Pharmacy)</h4>
                  <h5 className='px-md-5'>(Academic Year 2024-25)</h5>
                </div>

                <div className="col-12 px-md-5">
                  <div className='px-md-5'>
                      <Table className='tableAntiRagging' responsive="sm" style={{border:"1px solid #D5D8DC",padding:"10px"}}>
                        <thead>
                          <tr>
                            <th>Sr.No</th>
                            <th>Name</th>
                            <th>Designation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className='tableRow'>
                            <td>1</td>
                            <td> Mr. Patrekar Prasad Vasantrao</td>
                            <td>Principal</td>
                          </tr>
                          <tr className='tableRow'>
                            <td>2</td>
                            <td>Mr. Aniket Bhagwan Surve</td>
                            <td>Assistant Professor</td>
                          </tr>
                          <tr className='tableRow'>
                            <td>3</td>
                            <td>Ms.Jadhav Shital Tanaji</td>
                            <td>Assistant Professor</td>
                          </tr>
                          <tr className='tableRow'>
                            <td>4</td>
                            <td>Ms.Jadhav Vinaya Vijay</td>
                            <td>Assistant Professor</td>
                          </tr>
                          <tr className='tableRow'>
                            <td>5</td>
                            <td> Mr. Kumbhar Ashok Jambu</td>
                            <td>Office suprendent</td>
                          </tr>
                          <tr className='tableRow'>
                            <td>6</td>
                            <td> Mr. Mulla Miraso Gulab</td>
                            <td>Lab Attendent</td>
                          </tr>
                          <tr className='tableRow'>
                            <td>7</td>
                            <td> Adv.Kumbhar Tukaram R.</td>
                            <td>Lawyer</td>
                          </tr>
                          <tr className='tableRow'>
                            <td>8</td>
                            <td>Ms. Divate Shrimayee Jalindar</td>
                            <td>L.R. F.Y.B.PHARM</td>
                          </tr>
                          <tr className='tableRow'>
                            <td>9</td>
                            <td> Mr.Sumit Dilip Mali</td>
                            <td>C.R. F.Y.B.PHARM</td>
                          </tr>
                          <tr className='tableRow'>
                            <td>10</td>
                            <td>Mr. Dilip Ramchandra Mali</td>
                            <td>Parent Representive</td>
                          </tr>
                        </tbody>
                        </Table>
                  </div>
                </div>
</div>

</div>

<Footer />
    </>
  )
}

export default AntiRagging