import React, { useEffect } from 'react'
import HeaderPage from './HeaderPage'
import Footer from './Footer'
import clgImg from '../Assets/College.jpeg'

const AboutUsPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
<HeaderPage />

<div className="container-fluid">

            <div className="row p-0 text-center" style={{backgroundColor:"brown",color:"white"}}>
            <div className="col-12 py-3">
                {/* <p style={{fontSize:"25px"}}>You are here</p> */}
                <span> <span style={{color:"#f9a693"}}>Home</span> / About Us </span>
            </div>
          </div>

          <div className="row p-0">
              <div className="col-12 p-0">
                <img src={clgImg} alt="" style={{width:"100%",minHeight:"300px",maxHeight:"600px"}} />
              </div>
          </div>

        <div className="row p-0 py-5"  style={{backgroundColor:"white"}}>

            <div className="col-12 px-md-5">
            <h2 style={{ marginBottom: '-15px' }}>
                About Institute
            </h2>
            <span
              style={{
                color: '#926505',
                textDecoration: 'underline',
                fontWeight: 'bolder',
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </div>

          <div className="col-12 px-1 p-md-5">
            <p className='px-2 px-md-5'>
            "Eklavya College of Pharmacy, Vasumbe, Tasgaon" The college is located at a rural place called Vasumbe at around 2 km from Tasgaon, Tal: Miraj District: Sangli, Maharashtra. It is being by Sahayadri charitable trust which has a sound background in political and educational fields. The campus is of a total 5 acres of which 2.8 acres is planned for educational purpose. The said trust was formed on 11th August 1986 and are involved in social and educational work.

            Sahayadri charitable trust is formed for providing social and educational help from Sangli district of Maharashtra. Vasumbe, Tasgaon forms to be a belt where in the nearby places forms to be economically backward, drought areas whereby the students of Atpadi, Jath, Kavathe. Mahankal, Palus, Vita, etc are needed to travel a lot for acquiring education. Establishing a pharmacy college in this region would open a doorstep for these students to meet the needs of the society and industry.

            Sahayadri Charitable Trust, Sangli is located at Sangli district. Sangli District is one of the major centre for providing healthcare facilities to the people of the surrounding area. It is flooded with hospitals and are requiring large no of pharmacy graduates to provide the necessary healthcare / patient counselling. It also has few number of pharmaceutical industries in its area while there are numerous industries in the adjacent districts viz. Satara, Kolhapur and Solapur. Also Goa a hub for Pharmaceutical Manufacturing is around 200 KM from Sangli. Sangli has good water supply and has adequate amount of electricity from the power station located at Koyna. Hence there is a huge potential for industrial development in this area.
            </p>
          </div>


          <div className="col-12 px-md-5">
            <h2 style={{ marginBottom: '-15px' }}>
            Our Vision
            </h2>
            <span
              style={{
                color: '#926505',
                textDecoration: 'underline',
                fontWeight: 'bolder',
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </div>

          <div className="col-12 px-1 p-md-5">
            <p className='px-2 px-md-5'>
            To be a leader in Pharmaceutical education and practice with the ultimate goal Of Proficient, excellent and innovation patient care
            </p>
          </div>

          <div className="col-12 px-md-5">
            <h2 style={{ marginBottom: '-15px' }}>
            Our Mission
            </h2>
            <span
              style={{
                color: '#926505',
                textDecoration: 'underline',
                fontWeight: 'bolder',
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </div>

          <div className="col-12 px-1 p-md-5">
            <p className='px-2 px-md-5'>
            The mission of Eklavya college of Pharmacy is to change the socio-economic Scenario though development of professionally and socially obliged pharmacists With highest nobility and self respect
            </p>
          </div>

        </div>

</div>

<Footer />
    </>
  )
}

export default AboutUsPage