import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HeaderPage from '../HeaderPage'
import Footer from '../Footer'
import { accessImage, sportsFindAll } from '../AllAPIs';


const Sports = () => {

  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(sportsFindAll);
        setImages(response.data); 
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchData();
  }, []); 


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
<HeaderPage />

<div className="container-fluid">

    <div className="row p-0 text-center" style={{backgroundColor:"brown",color:"white"}}>
     <div className="col-12 py-3">
        {/* <p style={{fontSize:"25px"}}>You are here</p> */}
        <span> <span style={{color:"#f9a693"}}>Home / Events & Activities </span> / Sports</span>
     </div>
   </div>

   <div className="row p-0 py-5 px-md-5"  style={{backgroundColor:"white"}}>
          <div className="col-12 mb-5 text-start px-md-5">
            <h2 style={{ marginBottom: '-10px' }}>Sports</h2>
            <span
              style={{
                color: '#926505',
                textDecoration: 'underline',
                fontWeight: 'bolder',
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </div>

            <div className="col-12 px-md-5 pb-3">
              <p>Stay Fit. Stay Healthy place in the Eklavya student experience and is an enduring source of institutional pride. For more than 20 years, sports and athletics has provided formative leadership experiences for Eklavya students, alumni, family and friends. The unique combination of intellectual challenge, social engagement and competitive opportunities is the key to our success. The Sports program at Eklavya enjoys a rich tradition of competitive teams comprised of talented and disciplined students.</p>
            </div>

            <div className="col-12 p-0 px-md-5 px-2">
            <ul>
              {images.map((img_name, index) => (
                <li key={index}>
                 
                    <figure>
                      <img className="gallaryImg" src={`${accessImage}${img_name.img_name}`} alt={`img_name ${index + 1}`} />
                    </figure>
                 
                </li>
              ))}
            </ul>
          </div>
          
        </div>

</div>

<Footer />
    </>
  )
}

export default Sports