import React from 'react'
import './FooterCss.css';
import logo from "../Assets/logo.png"

const Footer = () => {
  return (
    <div className="container-fluid">
    {/* ==================footer2========================== */}

    <div className="row p-0 py-5"  style={{backgroundColor:"#F2F4F4 "}}>

    <div className="col-12 col-lg-3  text-center">
        <div className=''>
        <img src={logo} alt="img" width={90} height={80} /> <br />
            <span style={{fontSize:"12px"}}>Sahyadri Charitable Trust’s</span> <br />
            <span style={{fontSize:"20px"}}>Eklavya College of Pharmacy, Tasgaon</span>
        </div>
    </div>

    <div className="col-12 col-lg-6 pt-4 pt-md-0 text-center">

    <div className='text-start pb-3'>
        <h4 style={{marginBottom:"-15px"}}> QUICK LINKS  </h4>
        <span style={{color:"#ca725e",textDecoration:"underline",fontWeight:"bolder"}}> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
    </div>
        <div className='row p-0 footer text-start'>

                    <div className="col-12 col-md-3">
                        <li><a href="">Home</a></li>
                        <li><a href="#/aboutUs">About</a></li>
                        <li><a href="#/course1">B. Pharma</a></li>
                        <li><a href="#/course2">D. Pharma</a></li>                    
                        <li><a href="#/admission">Admissions</a></li>
                        <li><a href="#/academics1">Departments</a></li>
                        <li><a href="#/academics2">Faculty</a></li> 
                    </div>

                    <div className="col-12 col-md-5">
                        <li><a href="#/academics3">Syllabus</a></li>
                        <li><a href="#/academics4">Infrastructure</a></li>
                        <li><a href="#/academics5">Library</a></li>
                        <li><a href="#/alumni">Alumni</a></li>
                        <li><a href="#/events1">Seminar/Workshop/Guest Lecture</a></li>
                        <li><a href="#/events2">Industrial Visits</a></li>
                        <li><a href="#/events3">Educational Tour</a></li>
                    </div>

                    <div className="col-12 col-md-4">
                        <li><a href="#/events4">Culture</a></li>
                        <li><a href="#/events5">Sports</a></li>
                        <li><a href="#/students1">Grievance Cell</a></li>
                        <li><a href="#/students2">Anti Ragging</a></li>
                        <li><a href="#/students3">Scholarship</a></li>
                        <li><a href="#/students4">Student Achievements</a></li>
                        <li><a href="#/contactUs">Contact</a></li>                            
                    </div>

                                           
        </div>

                        
    </div>


    <div className="col-12 col-lg-3 pt-4 pt-md-0 text-center">

    <div className='text-start pb-3'>
        <h4 style={{marginBottom:"-15px"}}> KEEP IN TOUCH  </h4>
        <span style={{color:"#92ca725e6505",textDecoration:"underline",fontWeight:"bolder"}}> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
    </div>

    <div className='text-start' >
                <p style={{fontSize:"16px"}}> <i class="fa-solid fa-location-dot" style={{color:"#ca725e"}}></i> &nbsp;
                Eklavya College of Pharmacy,Tasgaon, DTE Tasgaon-Manerajuri Road, Vasumbe,Tasgaon. Tal.Tasgaon, Dist.Sangli. 416 312
                </p>
                <p style={{fontSize:"16px"}}><i class="fa-solid fa-phone" style={{color:"#ca725e"}}></i> &nbsp; 0234 6250701</p>
                <p style={{fontSize:"16px"}}><i class="fa-solid fa-envelope" style={{color:"#ca725e"}}></i> &nbsp; eklvyacop@gmail.com</p>
                
    </div>

    </div>



    <div class="footer1 col-12 text-center" style={{fontSize:"14px"}} >
    <hr style={{border:"1.5px solid #ca725e", opacity: ".3"}}/>
    <span > &copy; 2024 Eklavya College of Pharmacy, Tasgaon | Developed By : <span style={{fontWeight:"500"}} className='name'>TechnoAarv Solution</span></span>
    </div>


    </div>

</div>
  )
}

export default Footer