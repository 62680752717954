import React, { useEffect, useRef, useState } from 'react'
import './HomePageCss.css'
import clgImg from '../Assets/bg1.jpeg'
import clgImg1 from '../Assets/College.jpeg'
import autho1 from '../Assets/autho1.jpeg'
import autho2 from '../Assets/autho2.jpg'
import autho3 from '../Assets/autho3.jfif'
import blinkImg from '../Assets/blinkImg.gif'
import HeaderPage from './HeaderPage'
import Footer from './Footer'
import axios from 'axios';


import { Carousel } from 'react-bootstrap';
import { dashboardFindAll, downloadNewsEventsImage, downloadNoticeImage, newsEventsFindAll, noticeBoardFindAll,accessImage } from './AllAPIs'

const HomePage = () => {

  useEffect(() => {
    const disableBackButton = (event) => {
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = function () {
        window.history.pushState(null, null, window.location.href);
      };
    };

    disableBackButton();

    return () => {
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = null;
    };
  }, []);



  const handleDownload = async (id, fileName) => {
    try {
      // Make an API call to download the file
      const response = await axios.get(`${downloadNoticeImage}/${id}`, {
        responseType: 'blob', // Set the response type to blob
      });
  
      // Check if the response contains the success message
      if (response.data === "Image download successful") {
        // Image download successful, no need to do anything else
        console.log("Image download successful");
      } else {
        // Create a temporary URL for the downloaded file
        const url = window.URL.createObjectURL(new Blob([response.data]));
  
        // Create a link element
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.target = "_blank"; // Open in new tab/window
        document.body.appendChild(link);
        link.click();
  
        // Clean up the temporary URL
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };
  
  const handleDownload1 = async (id, fileName) => {
    try {
      const response = await axios.get(`${downloadNewsEventsImage}/${id}`, {
        responseType: 'blob', 
      });
         
      if (response.data === "Image download successful") {
    
        console.log("Image download successfully");
      } else {
        
        const url = window.URL.createObjectURL(new Blob([response.data]));
  
       
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
  
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };
  

  //======================welcome carousal imgs  API code==============================


  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(dashboardFindAll);
        console.log('====================================');
        const imgpath=`${accessImage}${response.data[1].image}`
        console.log('=============',imgpath)
        console.log('====================================');
        setImages(response.data); // Assuming response.data is an array of image URLs
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchData();
  }, []);


//=======================News & Events API code===========================================

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(newsEventsFindAll);
        setData(response.data); 
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []); 

  //=====================Notice Board API code===============================
  const [data1, setData1] = useState([]);

  useEffect(() => {
    const fetchData1 = async () => {
      try {
        const response = await axios.get(noticeBoardFindAll);
        setData1(response.data); 
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData1();
  }, []); 

   
   //====================================================

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  
  useEffect(() => {
    const scrollContainer = document.getElementById('scrollContainer');
    let scrollPosition = 5;

    const scrollInterval = setInterval(() => {
    
      scrollPosition += 5;

      if (scrollPosition >= scrollContainer.scrollHeight - scrollContainer.clientHeight) {
        scrollPosition = 5;
      }

     
      scrollContainer.scrollTo({
        top: scrollPosition,
        behavior: 'smooth',
      });
    }, 500);

    return () => clearInterval(scrollInterval);
  }, []); 

  useEffect(() => {
    const scrollContainer1 = document.getElementById('scrollContainer1');
    let scrollPosition = 5;

    const scrollInterval = setInterval(() => {
    
      scrollPosition += 5;

      if (scrollPosition >= scrollContainer1.scrollHeight - scrollContainer1.clientHeight) {
        scrollPosition = 5;
      }

     
      scrollContainer1.scrollTo({
        top: scrollPosition,
        behavior: 'smooth',
      });
    }, 500);

    return () => clearInterval(scrollInterval);
  }, []); 

  return (
    <>
      <HeaderPage />

      <div className="container-fluid">
        {/* =============================welcome code============================================= */}

        <div className="row p-0 py-5" style={{ backgroundColor: '#F2F4F4 ' }}>
          <div className="col-12 px-md-5">
            <h2 style={{ marginBottom: '-15px' }}>
              Welcome to Eklavya College of Pharmacy,Tasgaon
            </h2>
            <span
              style={{
                color: '#926505',
                textDecoration: 'underline',
                fontWeight: 'bolder',
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </div>


          <div className="col-12 col-md-7 py-3 image-box">
            <Carousel>
              {images.map((image, index) => (
                <Carousel.Item key={index} interval={2000}>
                  <img className="d-block w-100" src={`${accessImage}${image.image}`} alt={`Slide ${index + 1}`} />
                </Carousel.Item>
              ))}
            </Carousel>
          </div>

          <div
            className="col-12 col-md-5  pt-2 pt-md-5 py-5"
            style={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '',
              flexDirection: 'column',
              justifyItems: 'left',
            }}
          >
            <span>
              The mission of Eklavya college of Pharmacy is to change the
              socio-economic Scenario though development of professionally and
              socially obliged pharmacists With highest nobility and self
              respect ABOUT Eklavya College Sahayadri Charitable Trust, Sangli
              is located at Sangli district. Sangli District is one of the major
              centre for providing healthcare facilities to the people of the
              surrounding area. It is flooded with hospitals and are requiring
              large no of pharmacy graduates to provide the necessary healthcare
              / patient counselling. It also has few number of pharmaceutical
              industries in its area while there are numerous industries in the
              adjacent districts viz. Satara, Kolhapur and Solapur. Also Goa a
              hub for Pharmaceutical Manufacturing is around 200 KM from Sangli.
              has good water supply and has adequate amount of electricity from
              the power station located at Koyna. Hence there is a huge
              potential for industrial development in this area.
            </span>{' '}
            <br />
            <div className="col-12">
            <a href="#/aboutUs" className='knowMore'>Know More</a>
            </div>
          </div>
        </div>

        {/* ========================COURSES OFFERS================== */}

        <div className="row p-0 py-5" style={{ backgroundColor: '#a9341b' }}>
          <div className="col-12 px-md-5">
            <h2 style={{ marginBottom: '-15px', color: '#fff' }}>
              Courses Offers
            </h2>
            <span
              style={{
                color: '#ea826e',
                textDecoration: 'underline',
                fontWeight: 'bolder',
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </div>

          <div className="col-12 col-md-6 my-3 mb-5 mb-md-0 px-md-5">
            <div
              className="px-3 pb-2"
              style={{ boxShadow: '1px 1px 5px #a9341b' }}
            >
              <div className="row py-2">
                <h4 style={{ color: '#ffc1b5' }}>B. Pharma</h4>
              </div>

              <div className="row p-0 py-2 text-white">
                <div class="col-12 text-start">
                  <p>Diploma in Pharmacy</p>
                  <p>
                    Eligibility: 10+2 level of education in science stream, from
                    a recognized educational board
                  </p>
                  <p>Duration: 2 years</p>
                  <a href="#/course1" className='knowMore1'>Know More</a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 my-3 mb-5 mb-md-0 px-md-5">
            <div
              className="px-3 pb-2"
              style={{ boxShadow: '1px 1px 5px #a9341b' }}
            >
              <div className="row py-2">
                <h4 style={{ color: '#ffc1b5' }}>D. Pharma</h4>
              </div>

              <div className="row p-0 py-2 text-white">
                <div class="col-12 text-start">
                  <p>Bachelor of Pharmacy</p>
                  <p>
                    Eligibility: Candidates should have passed 10+2 in the
                    stream of science from a recognised board of education.
                  </p>
                  <p>Duration: 4 years</p>
                  <a href="#/course2" className='knowMore1'>Know More</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ========================News and Notice================== */}

        <div class="row p-0 px-md-5 py-5" style={{ backgroundColor: '#F2F4F4 ' }}>

          <div className="col-12 col-md-6 mb-5 mb-md-0">
            <div
              className="px-3 pb-2"
              style={{ boxShadow: '1px 1px 5px #a9341b' }}
            >
              <div className="row py-2">
                <h4 style={{ marginBottom: '-10px' }}>News & Events</h4>
                <span
                  style={{
                    color: '#926505',
                    textDecoration: 'underline',
                    fontWeight: 'bolder',
                  }}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </div>

              <div id="scrollContainer" className="row p-0 py-2" style={{ height: '300px', overflowY: 'hidden' }}>
                  <div className="scroll-content">
                    {data.map((item, index) => (
                      <div key={index} className="col-12 text-start">
                        <div className="p-2 alert fade alert-simple alert-warning alert-dismissible text-left font__family-montserrat font__size-14 font__weight-light brk-library-rendered rendered show" role="alert" data-brk-library="component__alert">
                      
                          &nbsp; {item.title}  &nbsp;&nbsp; {item.discription} 
                          {item.image && (
                            <span style={{ color: 'red', cursor: 'pointer' }} className="faa-flash" onClick={() => handleDownload1(item.id, item.image)}>
                              {' '}
                              &nbsp; <u title='download'>{item.image}</u>
                            </span>
                          )}
                         
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

            </div>
          </div>

          <div className="col-12 col-md-6">
            <div
              className="px-3 pb-2"
              style={{ boxShadow: '1px 1px 5px #a9341b' }}
            >
              <div className="row py-2">
                <h4 style={{ marginBottom: '-10px' }}>Notice Board</h4>
                <span
                  style={{
                    color: '#926505',
                    textDecoration: 'underline',
                    fontWeight: 'bolder',
                  }}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </div>

              <div id="scrollContainer1" className="row p-0 py-2" style={{ height: '300px', overflowY: 'hidden' }}>
                  <div className="scroll-content">
                    {data1.map((item, index) => (
                      <div key={index} className="col-12 text-start">
                        <div className="p-2 alert fade alert-simple alert-warning alert-dismissible text-left font__family-montserrat font__size-14 font__weight-light brk-library-rendered rendered show" role="alert" data-brk-library="component__alert">
                          {/* <span className="close font__size-18" data-dismiss="alert">
                            <img src={blinkImg} alt="new" style={{ width: '70px', height: '60px' }} />
                          </span> */}
                          &nbsp; {item.title}  &nbsp;&nbsp;
                          {item.pdf && (
                            <span style={{ color: 'red', cursor: 'pointer' }} className="faa-flash" onClick={() => handleDownload(item.id, item.pdf)}>
                              {' '}
                              &nbsp; <u title='download'>{item.pdf}</u>
                            </span>
                          )}
                        <br />

                        </div>
                      </div>
                    ))}
                  </div>
                </div>

            </div>
          </div>
        </div>


        {/* ========================Our Autorities1================== */}

        <div className="row p-0 py-5" style={{backgroundColor:"white"}}>
          <div className="col-12 text-start px-md-5">
            <h2 style={{ marginBottom: '-10px' }}>Our Authorities</h2>
            <span
              style={{
                color: '#926505',
                textDecoration: 'underline',
                fontWeight: 'bolder',
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </div>

          <section>
            <div class="container1">
              <div class="card">
                <div class="content1">
                  <div class="imgBx1">
                    <img src={autho1} alt="img" />
                  </div>
                  <div class="contentBx1 text-center">
                    <h3>Hon. Dr. Sikandar Jamadar</h3>
                    <span>President, Eklavya College of Pharmacy, Tasgaon</span>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="content1">
                  <div class="imgBx1">
                    <img src={autho2} alt="img" />
                  </div>
                  <div class="contentBx1 text-center">
                    <h3>Hon. Adv. Amjad Sikandar Jamadar</h3>
                    <span>Secretary, Eklavya College of Pharmacy, Tasgaon</span>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="content1">
                  <div class="imgBx1">
                    <img src={autho3} alt="img" />
                  </div>
                  <div class="contentBx1 text-center">
                    <h3>Mr. Prasad Vasantrao Patrekar</h3>
                    <span>Principal, Eklavya College of Pharmacy, Tasgaon</span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

      </div>

      <Footer />
    </>
  )
}

export default HomePage
