import React, { useEffect } from 'react'
import HeaderPage from '../HeaderPage'
import Footer from '../Footer'

const Grievance = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
<HeaderPage />

<div className="container-fluid">

      <div className="row p-0 text-center" style={{backgroundColor:"brown",color:"white"}}>
      <div className="col-12 py-3">
          {/* <p style={{fontSize:"25px"}}>You are here</p> */}
          <span> <span style={{color:"#f9a693"}}>Home / Student Corner </span> / Grievance Cell</span>
      </div>
    </div>

    <div className="row p-0 py-5"  style={{backgroundColor:"white"}}>
     
          <div className="col-12 px-md-5 py-3">
            <h2 style={{ marginBottom: '-15px' }}>
            Admission Policy
            </h2>
            <span
              style={{
                color: '#926505',
                textDecoration: 'underline',
                fontWeight: 'bolder',
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </div>
   </div>

   <div className="row px-2 px-md-5"  style={{backgroundColor:"white"}}>
              <div className="col-12 px-3 px-md-5">
                <h4>Eligibility Criteria of D. Pharma</h4>
              </div>
              
              <div className="col-12 px-3 px-md-5">
                <p> The admission process is governed by by Hon. Directorate of Technical Education, Maharashtra State, Mumbai. The vacant seats after Centralized Admission Procedure (CAP) will be filled at Institute level according to merit & as per guidelines of the Hon. Directorate of Technical Education, Maharashtra State, Mumbai. </p>
                <p>1) The Candidate should be an India National</p>
                <p>2) For the admission to first year of Diploma course in pharmacy, the candidate should have passed higher secondary certificate H.S.C. (std.XII) Examination of Maharashtra State Board of or its equivalent examination with at least 35% marks in aggregate with Physic, Chemistry, Biology Mathematics and English.</p>
                <p>3) Other than Maharashtra State Candidates shall be eligible for institute quota only.</p>
                <p>4) NRI/OCI/PIO, Children of Indian workers in the Gulf countries, Foreign National- i) Passed higher secondary certificate H.S.C. (std.XII) Examination with Physics & Chemistry as compulsory subject along with one of the subject Mathematics or Biology.(Botany & Zoology) ii) Any other criteria declared from time to time by the appropriate authority as defined under the Act.</p>
              </div>
   </div>

   <div className="row px-2 px-md-5"  style={{backgroundColor:"white"}}>
              <div className="col-12 px-3 px-md-5">
                <h4>Eligibility Criteria B. Pharma</h4>
              </div>
              
              <div className="col-12 px-3 px-md-5">
                <p> Centralized on-line admission process for B. Pharmacy (First Year), Direct Second Year B. Pharmacy. is governed by State Common Entrance Test Cell, Directorate of Technical Education Maharashtra every year. To participate in admission process students must have to appear for MHT CET 2024. </p>
                <p style={{fontSize:"16px"}}><b>ELIGIBILITY CRITERIA FOR B. PHARM. (INTAKE 60)</b></p>
                <p><b>Maharashtra State Candidature Candidates</b></p>
                <p>For First Year B. Pharm.</p>
                <p>1) The candidate should be an Indian National</p>
                <p>2) General Category: Passed HSC or its equivalent examination with Physics and Chemistry as compulsory subjects along with one of the Mathematics or Biology and obtained at least 45% marks (i.e. 135 marks / out of 300 marks)</p>
                <p>3) Reserved Category: at least 40% marks (i.e. 120 marks / out of 300 marks)</p>
                <p>4) should have appeared and Obtained Score in MHT- CET-2024</p>

                <p><b>All India Candidature Candidates </b></p>
                <p>1) Candidate should obtain non zero positive score in NEET-2024 or the candidate should have appeared in all the subjects in MHT-CET 2024 and should obtain non zero score in CET-2024 conducted by the Competent Authority. However, preference shall be given to the candidate obtaining non zero positive score in NEET over the candidates who obtained non zero score in CET.</p>

                <p><b>Eligibility Criteria For Direct Second Year B. Pharm.</b></p>
                <p>General Category: Passed Diploma Course in Pharmacy with at least 45 % marks from an All India Council for Technical Education or Pharmacy Council of India or Central or State Government approved Institution or its equivalent.</p>
                <p>Reserved Category: Passed Diploma Course in Pharmacy with at least (at least 40 % marks) in case of candidates of Backward class categories and Persons with disability belonging to Maharashtra State only from an All India Council for Technical Education or Pharmacy Council of India or Central or State Government approved Institution or its equivalent</p>
              </div>
   </div>
   
</div>

<Footer />
    </>
  )
}

export default Grievance