import React, { useState } from 'react'
import { FaUserCircle } from "react-icons/fa";
import { AiOutlineLogout } from "react-icons/ai";
import Typography from '@mui/material/Typography';
import { RiLockPasswordFill } from "react-icons/ri";
import { Box, Popover, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AiFillCloseCircle } from "react-icons/ai";
import { Button } from 'react-bootstrap';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import { toast } from 'react-toastify';
import { changePassword, dashboardCreate } from '../AdminAllAPIs';
import './ProfilePageCSS.css' ;

const ProfilePage = () => {
    const navigate = useNavigate()

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openModal, setOpenModal] = useState(false);

  const handleClickPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const open2 = Boolean(anchorEl);
  const id = open2 ? 'simple-popover' : undefined;


  const handleLogout = () => {
    sessionStorage.clear(); 
    navigate('/');
};


const [email, setEmail] = useState('')
const [password, setPassword] = useState('')
const [newPassword, setNewPassword] = useState('')


const handleEmail = (e) =>{
    setEmail(e.target.value)
  }
const handlePassword = (e) =>{
    setPassword(e.target.value)
  }
const handleNewPassword = (e) =>{
    setNewPassword(e.target.value)
  }


 //   ==========SUBMIT DATA API CODE================

 const handleSubmit = (event) => {
    event.preventDefault();

    const passObj ={
        email:email,
        password:password,
        newpassword:newPassword
    }


    axios.put(changePassword, passObj)
      .then(response => {
        if(response.data == "UPDATE"){

            toast.success('Password Updated Successfully!!', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
        }
        else if(response.data == "NOTFOUND"){

            toast.warn('Data Not Found !!', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
        }
       
        handleClose();
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };


//=====================================================================

  return (
    <div>
        <FaUserCircle style={{ fontSize: 30 }} title='Profile' aria-describedby={id} variant="contained" onClick={handleClickPopover} />

        <Popover
              id={id}
              open={open2}
              anchorEl={anchorEl}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Typography> <button className='profileButton' onClick={handleOpen}>Change Password &nbsp; <RiLockPasswordFill /></button> </Typography>
              <Typography> <button className='profileButton' onClick={handleLogout}>Logout &nbsp;<AiOutlineLogout /></button> </Typography>
            </Popover>


            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box className="modalBox">
                    <div className="col-12 text-end">
                    <AiFillCloseCircle onClick={handleClose} style={{ fontSize: 20, color: "#4d6d7c" }} />
                    </div>
                    <Typography id="modal-modal-title" style={{ padding: "10px", fontSize: "18px" }}>
                    Change Password
                    </Typography>
                    <hr />
                    <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ padding: "10px", fontSize: "14px", borderRadius: "10px" }}>
                    <form className="gf-form-group" onSubmit={handleSubmit}>

                    <div className="col-12 mt-2 col-md-7">
                        <TextField
                            type='text'
                            className=' w-100'
                            size='small'
                            required
                            id="email"
                            label="Email"
                            onChange={handleEmail}
                        />
                        </div>

                        <div className="col-12 mt-3 col-md-7">
                        <TextField
                            type='password'
                            className=' w-100'
                            size='small'
                            required
                            id="password"
                            label="Password"
                            onChange={handlePassword}
                        />
                        </div>

                        <div className="col-12 mt-3 col-md-7">
                        <TextField
                            type='password'
                            className=' w-100'
                            size='small'
                            required
                            id="newPassword"
                            label="New Password"
                            onChange={handleNewPassword}
                        />
                        </div>
                        <div className="col-12 mt-3 col-md-7">
                        <Button type="submit" style={{ padding: "8px 15px", borderRadius: "5px", backgroundColor: "#4d6d7c", color: "white" }}>Submit</Button>
                        </div>
                    </form>
                    </Typography>
                </Box>
                </Modal>

    </div>
  )
}

export default ProfilePage