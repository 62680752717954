import React, { useEffect, useState } from 'react';
import { Col, Dropdown, Nav } from 'react-bootstrap';
import { AiFillTrademarkCircle } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';
import { AiFillEdit } from "react-icons/ai";
import { AiFillDelete } from "react-icons/ai";
import { AiFillCloseCircle } from "react-icons/ai";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import axios from 'axios';
import {  contactFindAll } from '../AdminAllAPIs';
import { toast } from 'react-toastify';
import ProfilePage from './ProfilePage';
import './ContactUsCSS.css' ;

const ContactUs = () => {

  const navigate = useNavigate();

     // ===============Check session storage==============
  useEffect(() => {
    if (!sessionStorage.getItem('userData')) { 
      navigate('/login');
    }
  }, [navigate]);

//==========================================================================

  
  const [tableData, setTableData] = useState([]);


  // =================GET DATA API CODE======================


  const fetchData = () =>{
      axios.get(contactFindAll)
      .then((response) => {
        setTableData(response.data)
      })
      .catch((err) => console.log(err))
    }

    useEffect(() =>{
      fetchData()
    },[])



// ============================================================================
 


  return (
    <>
        <div className='row m-0' style={{borderRadius:"5px",padding:"10px 15px",display:"flex",justifyContent:"space-between",alignItems:"center",backgroundColor:"#4d6d7c",color:"white"}}>
          <div className="col-6" style={{fontSize:"18px"}}>
              <span>Contact Us</span>
          </div>
          <div className="col-6 text-end">
          <ProfilePage />
          </div>

      </div>

      <div className="row m-0 mt-5">


          <div className="col-12 p-0 pt-2">
          <TableContainer component={Paper} style={{boxShadow:"1px 1px 5px",width:"100%"}}>
              <Table aria-label="simple table">
                  <TableHead style={{backgroundColor:"#7aa7bc"}}>
                  <TableRow>
                      <TableCell>SR.NO</TableCell>
                      <TableCell>NAME</TableCell>
                      <TableCell align="left">MOBILE NO</TableCell>
                      <TableCell align="left">MESSAGE</TableCell>
                  </TableRow>
                  </TableHead>
                  <TableBody>
                  {tableData.map((row,index) => (
                      <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                      <TableCell component="th" scope="row">
                          {index+1}
                      </TableCell>
                      <TableCell component="th" scope="row">
                          {row.f_name}
                      </TableCell>
                      <TableCell align="left">{row.mob_no}</TableCell>
                      <TableCell align="left">{row.message}</TableCell>

                      </TableRow>
                  ))}
                  </TableBody>
              </Table>
          </TableContainer>
          </div>



      </div>
    </>
  );
};


export default ContactUs