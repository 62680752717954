import React, { useState } from 'react';
import './HeaderCss.css';
import logo from "../Assets/logo.png"




const HeaderPage = () => {

    const [navLinksLeft, setNavLinksLeft] = useState('-100%');
    const [showSubMenu1, setShowSubMenu1] = useState(false);
    const [showSubMenu2, setShowSubMenu2] = useState(false);
    const [showSubMenu3, setShowSubMenu3] = useState(false);
    const [showSubMenu4, setShowSubMenu4] = useState(false);
  
  
  
    const toggleNavLinks = () => {
      setNavLinksLeft((prevNavLinksLeft) =>
        prevNavLinksLeft === '0%' ? '-100%' : '0%'
      );
    };
  
    const toggleSubMenu1 = () => {
      setShowSubMenu1((prevShowSubMenu1) => !prevShowSubMenu1);
    };
    
  
    const toggleSubMenu2 = () => {
      setShowSubMenu2((prevShowSubMenu2) => !prevShowSubMenu2);
    };
  
    const toggleSubMenu3 = () => {
      setShowSubMenu3((prevShowSubMenu3) => !prevShowSubMenu3);
    };
    const toggleSubMenu4 = () => {
      setShowSubMenu4((prevShowSubMenu4) => !prevShowSubMenu4);
    };
  
    const handleSubMenuClick = (submenu) => {
      switch (submenu) {
        case 1:
          toggleSubMenu1();
          break;
        case 2:
          toggleSubMenu2();
          break;
        case 3:
          toggleSubMenu3();
          break;
        case 4:
          toggleSubMenu4();
          break;
        default:
          break;
      }
    };
  
  //===========================================================================

  //======================================================================================


  return (
    <div className="container-fluid">
    
    
        <div className='row p-0 pb-3' style={{backgroundImage:"linear-gradient(135deg, #fdfcfb 0%, #e2d1c3 100%)"}}>

        <div className="col-12 p-0">
                <div className="row p-0 m-0 pb-2" >
                    <div className="col-12 col-md-8"></div>
                    <div className="col-12 col-md-4 p-1 px-3 text-center" style={{borderRadius:"0px 0px 0px 30px",backgroundColor:"#526f7c"}}>
                        <span style={{fontSize:"16px",color:"#fff"}}><i class="fa-solid fa-phone" style={{color:"#fff"}}></i> &nbsp; 0234 6250701</span> &nbsp;&nbsp;&nbsp;&nbsp;
                        <span style={{fontSize:"16px",color:"#fff"}}><i class="fa-solid fa-envelope" style={{color:"#fff"}}></i> &nbsp; eklvyacop@gmail.com</span>
                    </div>
                </div>
        </div>
				
				<div className="col-12 col-md-2 p-0 text-center text-xl-end">
                    <span className="navbar-brand" href="index.html"><img src={logo} style={{width: "100px",}} alt="" /></span>
                </div>

				    <div className="col-12 col-md-10 p-0 ps-xl-5" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>

                       <div className='pt-3 pt-md-0 text-center text-md-start'>
                            <span>Sahyadri Charitable Trust’s</span>
                                <h2 style={{fontWeight: "bold", fontSize: "30px", color: "#A9341B"}}>Eklavya College of
                                    Pharmacy, Tasgaon</h2>
                                <p style={{fontSize: "14px",padding:"0px 2px"}}>Affiliated to DBATU, Recognized by Govt. Of Maharashtra & Approved by
                                    PCI (New Delhi),DTE Tasgaon-Manerajuri Road, Vasumbe, Tasgaon. Tal.Tasgaon, Dist.Sangli. 416 312
                                </p>
                       </div>

                    </div>
		</div>

{/* =====================navbar=================== */}

        <div className="row p-0">
          <div className="col-12 p-0">
            <nav>
                <div className="navbar">
                <i className="bx bx-menu" onClick={toggleNavLinks}></i>

                <div className="nav-links" style={{ left: navLinksLeft,overflowInline:"hidden" }}>
                    <div className="sidebar-logo">
                    <i className="bx bx-x" onClick={toggleNavLinks}></i>
                    </div>
                    <ul className="links">

                    <li>
                        <a href="#">Home</a>
                    </li>

                    <li>
                        <a href="#/aboutUs">About</a>
                    </li>

                    <li>
                        <a onClick={() => handleSubMenuClick(1)}>Courses</a>
                        <i
                        className="bx bxs-chevron-down htmlcss-arrow arrow"
                        onClick={toggleSubMenu1}
                        ></i>
                        <ul
                        className={`htmlCss-sub-menu sub-menu ${
                            showSubMenu1 ? 'show1' : ''
                        }`}
                        >
                        <li>
                            <a href="#/course1">B. Pharma</a>
                        </li>
                        <li>
                            <a href="#/course2">D. Pharma</a>
                        </li>
                        </ul>
                    </li>

                    <li>
                        <a href="#/admission">Admissions</a>
                    </li>

                    <li>
                        <a  onClick={() => handleSubMenuClick(2)}>Academics</a>
                        <i
                        className="bx bxs-chevron-down js-arrow arrow"
                        onClick={toggleSubMenu2}
                        ></i>
                        <ul
                        className={`js-sub-menu sub-menu ${
                            showSubMenu2 ? 'show2' : ''
                        }`}
                        >
                        <li>
                            <a href="#/academics1">Departments</a>
                        </li>
                        <li>
                            <a href="#/academics2">Faculty</a>
                        </li>
                        <li>
                            <a href="#/academics3">Syllabus</a>
                        </li>
                        <li>
                            <a href="#/academics4">Infrastructure</a>
                        </li>
                        <li>
                            <a href="#/academics5">Library</a>
                        </li>
                        </ul>
                    </li>

                    <li>
                        <a href="#/alumni">Alumni</a>
                    </li>

                    <li>
                        <a onClick={() => handleSubMenuClick(3)}>Events & Activities</a>
                        <i
                        className="bx bxs-chevron-down js-arrow arrow"
                        onClick={toggleSubMenu3}
                        ></i>
                        <ul
                        className={`js-sub-menu sub-menu ${
                            showSubMenu3 ? 'show3' : ''
                        }`}
                        >
                        <li>
                            <a href="#/events1">Seminar/Workshop/Guest Lecture</a>
                        </li>
                        <li>
                            <a href="#/events2">Industrial Visit</a>
                        </li>
                        <li>
                            <a href="#/events3">Educational Tour</a>
                        </li>
                        <li>
                            <a href="#/events4">Culture</a>
                        </li>
                        <li>
                            <a href="#/events5">Sports</a>
                        </li>
                        </ul>
                    </li>

                    <li>
                        <a  onClick={() => handleSubMenuClick(4)}>Student Corner</a>
                        <i
                        className="bx bxs-chevron-down js-arrow arrow"
                        onClick={toggleSubMenu4}
                        ></i>
                        <ul
                        className={`js-sub-menu sub-menu ${
                            showSubMenu4 ? 'show4' : ''
                        }`}
                        >
                        <li>
                            <a href="#/students1">Grievance Cell</a>
                        </li>
                        <li>
                            <a href="#/students2">Anti Ragging Commity</a>
                        </li>
                        <li>
                            <a href="#/students3">Scholarship</a>
                        </li>
                        <li>
                            <a href="#/students4">Student Achievements</a>
                        </li>
                        </ul>
                    </li>

                    <li>
                        <a href="#/contactUs">Contact Us</a>
                    </li>
                    </ul>
                </div>
                
                </div>
            </nav>
          </div>
        </div>


    </div>
  )
}

export default HeaderPage